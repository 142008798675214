import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUpcomingEvents } from "../../../Redux/Slices/UpComingEvents/UpComingEventSlice";
import useCommonStyles from "../../../Styles/CommonStyles";
import { Box, Button, CircularProgress, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { ReactComponent as PencilWriteIcon } from "../../../assets/UpcomingEvent/PencilWriteIcon.svg"
import { ReactComponent as EyeIcon } from "../../../assets/UpcomingEvent/EyeIcon.svg"
import { ReactComponent as BinIcon } from "../../../assets/UpcomingEvent/BinIcon.svg";
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((MenuProps) => (
    <Menu
        elevation={0}
        sx={{ maxHeight: 180 }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...MenuProps}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: "black",
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function UpcomingEventList({ }) {
    const dispatch = useDispatch();
    const commonStyles = useCommonStyles();
    const navigate = useNavigate()

    const [tooltipText, setTooltipText] = useState("Copy to clipboard")
    const [hostAnchor, setHostAnchor] = useState(null)
    const [hostDetail, setHostDetail] = useState(null)

    const upcomingEventData = useSelector(state => state?.UpComingEventData?.upcomingEvents)
    const upcomingEventsLoading = useSelector(state => state?.UpComingEventData?.upcomingEventsLoading)

    useEffect(() => {
        dispatch(getUpcomingEvents())
    }, [])

    const getStatus = (value) => {
        let statusData = {};
        if (value === "Published-") {
            statusData = {

                color: '#009C2C',
                fontWeight: '700',
                backgroundColor: '#0ca33652',

            }
        } else if (value === "Published") {
            statusData = {
                color: '#0B6BC2',
                fontWeight: '700',
                backgroundColor: 'rgba(11, 107, 194, 0.2)',

            }

        } else {
            statusData = {
                color: '#009C2C',
                fontWeight: '700',
                backgroundColor: '#0ca33652',
            }
        }

        return statusData;
    }



    const resetTooltip = () => {
        if (tooltipText === "Copy to clipboard") {
            return
        }
        setTooltipText("Copy to clipboard");

    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setTooltipText(`Copied : ${text}`);
    }



    const handleCloseMenu = () => {
        setHostAnchor(null)
        setHostDetail(null)
    }


    const handleClickHost = (event, customer) => {
        setHostDetail([...customer])
        setHostAnchor(event.currentTarget)
    }

    return <>
        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={hostAnchor}
            open={Boolean(hostAnchor)}
            onClose={handleCloseMenu}
        >
            {hostDetail && Array.isArray(hostDetail) ? hostDetail.map((item) => (
                <MenuItem sx={{ fontSize: '0.775rem' }} onClick={handleCloseMenu} disableRipple>
                    {item?.host && item?.host?.length ? item?.host[0]?.email : ""}
                </MenuItem>
            )) : ""
            }
        </StyledMenu>

        <Box mt={2}>

        </Box>
        <TableContainer sx={{ overflowX: 'unset' }} component={Paper} elevation={0} >
            <Table size="small" sx={{ mb: 1 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{ width: '5%' }} >EVENT</TableCell>
                        <TableCell align="left" style={{ width: '15%' }}>TITLE</TableCell>
                        <TableCell align='left' sx={{ width: '20%' }} >HOSTS</TableCell>
                        <TableCell align='center' sx={{ width: '20%' }} > DATE & TIME</TableCell>
                        <TableCell align="center" style={{ width: '15%' }}>STATUS</TableCell>
                        <TableCell align="center" style={{ width: '10%' }}>LINK</TableCell>
                        <TableCell align="center" style={{ width: '15%' }}>ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {upcomingEventsLoading ?
                        <TableCell colSpan={8} align="center"><CircularProgress /> </TableCell>
                        : Array.isArray(upcomingEventData?.data?.events) &&
                            upcomingEventData?.data?.events?.length ?

                            upcomingEventData?.data?.events?.map((row, i) => (
                                <TableRow key={row.id}
                                >
                                    <TableCell align="center">{row.uuid}</TableCell>

                                    <TableCell align='left' component="th" scope="row">
                                        {row.title}
                                    </TableCell>

                                    <TableCell align='left' component="th" scope="row">
                                        <Box
                                            onClick={(e) => { if (row?.host?.length) handleClickHost(e, row?.host) }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {row?.host?.length ? row?.host?.slice(0, 3).map((item) => {
                                                return <Typography sx={{ fontSize: '14px' }}>{
                                                    item?.host &&
                                                        Array.isArray(item?.host) &&
                                                        item?.host?.length ? item?.host[0].email : ''}
                                                </Typography>
                                            }) : "-"}

                                            {row?.host?.length > 3 ? <Typography sx={{ fontSize: '14px' }}>+ {row?.host?.length - 3}</Typography> : null}
                                        </Box>

                                    </TableCell>


                                    <TableCell align="center">
                                        <Typography sx={{ fontSize: '14px' }}> {row?.duration_start_date ? moment(row.duration_start_date).format("D MMMM YYYY") : "-"}</Typography>

                                        {row?.duration_from_time && row?.duration_to_time ?
                                            <> {moment(row.duration_from_time, 'HH:mm:ss').format('h:mm A')} - {moment(row.duration_to_time, 'HH:mm:ss').format('h:mm A')}</>
                                            : null
                                        }

                                    </TableCell>

                                    <TableCell align="right">
                                        {/* {row.status} */}
                                        {row.status ? <Typography sx={{
                                            display: 'flex',
                                            justifyContent: "center",
                                            minHeight: "35px",
                                            maxWidth: '150px',
                                            minWidth: "115px",
                                            mt: '5px',
                                            fontWeight: '500',
                                            alignItems: 'center',
                                            borderRadius: '6px',
                                            px: '5px',
                                            fontSize: '12px',
                                            ...getStatus(row.status)
                                        }}>
                                            {row.status}
                                        </Typography> : null}

                                    </TableCell>

                                    <TableCell align="right">
                                        <Tooltip title={tooltipText}>
                                            <Button
                                                variant="contained"
                                                endIcon={<ContentCopyIcon />}
                                                sx={{ borderRadius: '20px' }}
                                                onClick={() => copyToClipboard(row?.link)}
                                                onMouseOut={resetTooltip}
                                            >Link
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    borderRadius: '8px',
                                                    overflow: 'hidden',
                                                    border: '1px solid #D5D5D5',
                                                    maxWidth: 'max-content',
                                                    backgroundColor: '#FAFBFD'
                                                }}
                                            >
                                                <Box sx={{ borderRight: '1px solid #D5D5D5', padding: "2px" }}>
                                                    <IconButton aria-label="view" >
                                                        <EyeIcon />
                                                    </IconButton>
                                                </Box>

                                                <Box sx={{ borderRight: '1px solid #D5D5D5', padding: "2px" }}>
                                                    <IconButton aria-label="edit"
                                                        onClick={() => navigate(`/edit_event/${row.id}`)}
                                                    >
                                                        <PencilWriteIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box sx={{ padding: "2px" }}>
                                                    <IconButton aria-label="delete" sx={{ color: 'red' }}>
                                                        <BinIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </TableCell>

                                </TableRow>
                            ))
                            : <TableCell colSpan={8} align="center">No data found</TableCell>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
