import { Fragment } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './Pages/LandingPage';
// import Header from './Components/Header';
// import NotFound from './Components/PageNotFound';
// import UpcomingEvents from './Pages/UpcomingEvents';
// import CreateEvent from './Pages/Admin/CreateEvent';
import SnackBarPopUp from './Components/common/SnackBarPopUp';
import Routing from './Routes/Routing';

function App() {
  return (
    <Fragment>
      <SnackBarPopUp />
      <BrowserRouter >
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path="/*" element={<Routing />} />
        </Routes>
      </BrowserRouter>
    </Fragment>)
}

export default App;
