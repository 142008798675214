
import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipantsListByBookingId, setGetParticipantsListByBookingIdToNull } from '../../../Redux/Slices/Host/LiveEvents/HostLiveEventSlice';
import Loader from '../../../Components/common/Loader';
import { isJSONString } from '../../../Utils';
import ValidationView from '../../../Components/common/ValidationView';

const QRScanner = ({ handleScan = null }) => {
  const videoElementRef = useRef(null);
  const dispatch = useDispatch();

  const [scanned, setScannedText] = useState('');
  const [showErr, setShowErr] = useState(false);

  const getParticipantsListByBookingIdData = useSelector(state => state?.HostLiveEventData?.getParticipantsListByBookingId)
  const getParticipantsListByBookingIdLoading = useSelector(state => state?.HostLiveEventData?.getParticipantsListByBookingIdLoading)


  useEffect(() => {
    if (getParticipantsListByBookingIdData?.success === true) {
      // console.log(getParticipantsListByBookingIdData, 'getParticipantsListByBookingIdData');
      setShowErr(false)
      handleScan(getParticipantsListByBookingIdData?.data?.participants_list)
      dispatch(setGetParticipantsListByBookingIdToNull());

    } else if (scanned) {
      setShowErr(true)
      // setScannedText('')
      dispatch(setGetParticipantsListByBookingIdToNull());
    }
  }, [getParticipantsListByBookingIdData]);

  useEffect(() => {
    if (scanned) {
      let apiData = isJSONString(scanned) ? JSON.parse(scanned) : scanned
      dispatch(getParticipantsListByBookingId(apiData))
    }
  }, [scanned])

  useEffect(() => {
    const video = videoElementRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        // console.log('decoded qr code:', result);
        if (!scanned) setScannedText(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );

    qrScanner.setInversionMode('both')
    qrScanner.start();
    console.log('start');

    return () => {
      // console.log(qrScanner);
      qrScanner.stop();
      qrScanner.destroy();
      setScannedText('');
      setShowErr(false)
    };
  }, []);

  // const qrScanner = new QrScanner(videoElement, (result) =>
  //   console.log('decoded qr code:', result)
  // );

  return (<>
    <Loader open={getParticipantsListByBookingIdLoading} />
    <div>
      <div className="videoWrapper">
        <video className="qrVideo" ref={videoElementRef} />
      </div>
    </div>
    {showErr ? <ValidationView sx={{ fontSize: "12px", textAlign: 'center' }}>QR Code Invalid</ValidationView> : null}
  </>);
};

export default QRScanner;
