import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../AxiosInstance";

const initialState = {
    loading: false,
    loginStatus: null,

}

export const login = createAsyncThunk('login', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/login`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})



const Login = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginSliceToNull: (state) => {
            state.loginStatus = null
        },


    },
    extraReducers: (builders) => {
        //login
        builders.addCase(login.fulfilled, (state, action) => {
            state.loginStatus = action.payload.data
            state.loading = false
        })
        builders.addCase(login.pending, (state, action) => {
            state.loading = true
        })
        builders.addCase(login.rejected, (state, action) => {
            state.loginStatus = action?.payload?.response?.data
            state.loading = false
        })
    }
})

export const { setLoginSliceToNull } = Login.actions
export default Login.reducer