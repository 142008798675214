import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { resetSnackBarData } from '../../Redux/Slices/common/SnackbarSlice';

export default function SnackBarPopUp({ }) {
    const [open, setOpen] = React.useState(false);
    const [snackBarState, setSnackBarState] = React.useState({});
    const dispatch = useDispatch();

    const snackBarData = useSelector(state => state.SnackBarData);

    React.useEffect(() => {
        
        if (snackBarData.openStatus === true) {
            setSnackBarState(snackBarData);
            dispatch(resetSnackBarData());
            setOpen(true);
        } else if (snackBarData.openStatus === true && snackBarState && open) {
            setOpen(false);
        }
    }, [snackBarData])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        dispatch(resetSnackBarData());
        // setSnackBarState({});
    };

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={snackBarState?.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackBarState?.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
