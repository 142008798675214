import {
    ArrowBack as ArrowBackIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Close as CloseIcon,
    Done as DoneIcon
} from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Grid, IconButton, Modal, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../assets/UpcomingEvent/LocationIcon.svg";
import useCommonStyles from "../../../Styles/CommonStyles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHostUpcomingEvents, getParticipantsList, setParticipantsListToNull, setUpdateBookingStatusToNull, updateBookingStatus } from "../../../Redux/Slices/Host/UpcomingEvents/HostUpcomingEventSlice";
import { ReactComponent as CalendarIcon } from "../../../assets/UpcomingEvent/CalendarIcon.svg";
import { ReactComponent as ClockIcon } from "../../../assets/UpcomingEvent/ClockIcon.svg";
import moment from "moment";
import { openSnackbar } from "../../../Redux/Slices/common/SnackbarSlice";

function TabPanel(props) {

    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{}}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function ParticipantManagement(params) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const commonStyles = useCommonStyles();
    const { id: eventId } = useParams();

    const [eventData, setEventData] = useState(null);
    const [openParticipantPopup, setOpenParticipantPopup] = useState(false);
    const [value, setValue] = useState(0)

    const participantsListData = useSelector(state => state?.HostUpcomingEventData?.participantsList)
    const participantsListLoading = useSelector(state => state?.HostUpcomingEventData?.participantsListLoading)

    const updateBookingStatusData = useSelector(state => state?.HostUpcomingEventData?.updateBookingStatus)
    const updateBookingStatusLoading = useSelector(state => state?.HostUpcomingEventData?.updateBookingStatusLoading)

    const upcomingEventData = useSelector(state => state?.HostUpcomingEventData?.upcomingEvents);

    const EventAddress = Array.isArray(eventData?.event) && eventData?.event[0] ? eventData?.event[0]?.address?.split(",") : []

    const RegTypeFields = {
        1: { key: null, label: "Single Rotarian", fields: null },
        2: {
            key: "couplebooking",
            label: 'Couple Registration',
            fields: [
                { label: 'SPOUSE NAME', key: 'spouse_name' },
                { label: 'SPOUSE MOBILE', key: 'spouse_phone' },
                { label: 'SPOUSE FOOD PREFERENCE', key: 'spouse_meal_preference' },
            ]
        },
        3: {
            key: "rajadirajabooking",
            label: 'Maharaja Circle Rajadiraja',
            fields: [
                { label: 'SPOUSE NAME', key: 'spouse_name' },
                { label: 'SPOUSE MOBILE', key: 'spouse_phone' },
                { label: 'SPOUSE FOOD PREFERENCE', key: 'spouse_meal_preference' },
                { label: '1ST ANNET NAME(BELOW 12 YEARS)', key: 'first_annet_name' },
                { label: '2ST ANNET NAME(BELOW 12 YEARS)', key: 'second_annet_name' },
            ]
        },
        4: {
            key: "yuvarajabooking",
            label: 'Maharaja Circle Yuvaraja',
            fields: [
                { label: 'SPOUSE NAME', key: 'spouse_name' },
                { label: 'SPOUSE MOBILE', key: 'spouse_phone' },
                { label: 'SPOUSE FOOD PREFERENCE', key: 'spouse_meal_preference' },
                { label: '1ST ANNET NAME(BELOW 12 YEARS)', key: 'first_annet_name' }
            ]
        },
        5: { key: null, label: "Guest Registration", fields: null },
    }

    console.log(openParticipantPopup, 'updateBookingStatusData');

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(getParticipantsList({ event_id: eventId }))

        if (upcomingEventData === null) {
            dispatch(getHostUpcomingEvents())
        }

        return () => {
            dispatch(setParticipantsListToNull())
        }
    }, [])

    useEffect(() => {
        if (upcomingEventData?.data?.events && Array.isArray(upcomingEventData?.data?.events)) {
            const filterData = upcomingEventData?.data?.events?.filter(fval => fval.event_id == eventId)[0]


            if (filterData) setEventData(filterData)
            else setEventData(null)
        }

    }, [upcomingEventData])

    useEffect(() => {

        if (updateBookingStatusData?.success === true) {
            dispatch(openSnackbar({ message: updateBookingStatusData?.toast, severity: 'success' }))
            setOpenParticipantPopup(false)
            dispatch(setUpdateBookingStatusToNull())
        }
        else if (updateBookingStatusData?.success === false) {
            dispatch(openSnackbar({ message: updateBookingStatusData?.toast }))
            // setOpenParticipantPopup(false)
            dispatch(setUpdateBookingStatusToNull())
        }

    }, [updateBookingStatusData])

    const findDuration = (startTime, endTime) => {

        if (startTime && endTime) {
            let start = moment(startTime, 'HH:mm');
            let end = moment(endTime, 'HH:mm');



            // Calculate the duration
            let duration = moment.duration(end.diff(start));

            // Get the duration in hours and minutes
            let hours = Math.floor(duration.asHours());
            let minutes = duration.minutes();

            return `${hours} Hours ${Number(minutes) > 0 ? `${minutes} Minutes` : ''}`
        } else return "-"
    }

    const handleAccept = () => {
        // console.log(openParticipantPopup, 'openParticipantPopup');
        dispatch(updateBookingStatus({ event_id: eventId, booking_id: openParticipantPopup?.id }))
    }


    const handleCloseParticipantPopup = () => {
        setOpenParticipantPopup(false)
    }

    const renderTable = () => {
        return <Box >
            <TableContainer sx={{ overflowX: 'auto', maxWidth: '100%' }} component={Paper} elevation={0} >
                <Table size="small" sx={{ mb: 1, maxWidth: '100%' }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' style={{ width: '5%' }} >NO.</TableCell>
                            <TableCell align="left" style={{ width: '20%' }}>Name</TableCell>
                            <TableCell align='left' sx={{ width: '15%' }} > Email</TableCell>
                            <TableCell align="left" style={{ width: '15%' }}>Designation</TableCell>
                            <TableCell align="left" style={{ width: '15%' }}>Club name</TableCell>
                            <TableCell align="left" style={{ width: '10%' }}>Phone no</TableCell>
                            <TableCell align="left" style={{ width: '10%' }}>Food preference</TableCell>
                            <TableCell align="center" style={{ width: '10%' }}></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {participantsListLoading ?
                            <TableCell colSpan={8} align="center"><CircularProgress /> </TableCell>
                            : Array.isArray(participantsListData?.data?.participants_list) &&
                                participantsListData?.data?.participants_list?.length ?

                                participantsListData?.data?.participants_list?.map((row, i) => (
                                    <TableRow key={row.id}
                                    >
                                        <TableCell>{row?.id ?? "-"}</TableCell>
                                        <TableCell>{row?.full_name ?? "-"}</TableCell>
                                        <TableCell>{row?.email ?? "-"}</TableCell>
                                        <TableCell>{row?.designation ?? "-"}</TableCell>
                                        <TableCell>{row?.club_name ?? "-"}</TableCell>
                                        <TableCell>{row?.phone ?? "-"}</TableCell>
                                        <TableCell>
                                            <Box>
                                                {Array.isArray(row?.bookingfood_availability) ?
                                                    row?.bookingfood_availability?.map((item) => {
                                                        return <Typography sx={{ fontSize: '14px' }}>{item?.food_type?.name}</Typography>
                                                    }) : null}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                endIcon={<ArrowForwardIosIcon style={{ fontSize: '14px' }} />}
                                                onClick={() => setOpenParticipantPopup(row)}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>))
                                : <TableCell colSpan={8} align="center">No data found</TableCell>
                        }

                    </TableBody>

                </Table>
            </TableContainer>
        </Box>
    }

    const renderParticipantPopup = () => {

        let regData = null
        if (RegTypeFields[openParticipantPopup?.registration_type_id]) {

            const key = RegTypeFields[openParticipantPopup?.registration_type_id].key;

            if (key) {
                regData = openParticipantPopup[key]
            }
        }

        return <Box sx={{ borderRadius: '10px', display: 'flex', flexDirection: 'column', backgroundColor: '#fff', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 15px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Participant</Typography>

                <IconButton sx={{}} onClick={() => handleCloseParticipantPopup()}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{
                flexGrow: 1,
                padding: '20px',
                borderRadius: '0px 0px 10px 10px',
                borderTop: '1px solid #A9A9A980',
                backgroundColor: '#F5F6FA'
            }}>
                <Grid container spacing={2} >

                    <Grid item sm={6} xs={12}>
                        <Box className={commonStyles.customBox}>

                            <Box margin={1}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Details</Typography>
                                <Box mt={1}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>NAME</Typography>
                                    <Typography sx={{ fontSize: '12px' }}>{openParticipantPopup?.full_name ?? "-"}</Typography>
                                </Box>

                                <Box mt={1}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>DESIGNATION</Typography>
                                    <Typography sx={{ fontSize: '12px' }}>{openParticipantPopup?.designation ?? "-"}</Typography>
                                </Box>

                                <Box mt={1}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>CLUB NAME</Typography>
                                    <Typography sx={{ fontSize: '12px' }}>{openParticipantPopup?.club_name ?? "-"}</Typography>
                                </Box>

                                <Box mt={1}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>PHONE NO</Typography>
                                    <Typography sx={{ fontSize: '12px' }}>{openParticipantPopup?.phone ?? "-"}</Typography>
                                </Box>

                                <Box mt={1}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>EMAIL</Typography>
                                    <Typography sx={{ fontSize: '12px' }}>{openParticipantPopup?.email ?? "-"}</Typography>
                                </Box>

                                <Box mt={1}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>FOOD PREFERENCE</Typography>

                                    <Box>
                                        {Array.isArray(openParticipantPopup?.bookingfood_availability) ?
                                            openParticipantPopup?.bookingfood_availability?.map((item) => {
                                                return <Typography sx={{ fontSize: '12px' }}>{item?.food_type?.name}</Typography>
                                            }) : null}
                                    </Box>

                                </Box>
                            </Box>

                        </Box>
                    </Grid>


                    <Grid item sm={6} xs={12}>
                        <Box className={commonStyles.customBox}>

                            <Box margin={1}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Registration</Typography>

                                {RegTypeFields[openParticipantPopup?.registration_type_id] ? <>

                                    <Box mt={1}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>REGISTRATION TYPE</Typography>
                                        <Typography sx={{ fontSize: '12px' }}>
                                            {
                                                RegTypeFields[openParticipantPopup?.registration_type_id] &&
                                                    RegTypeFields[openParticipantPopup?.registration_type_id]?.label ?
                                                    RegTypeFields[openParticipantPopup?.registration_type_id]?.label : "-"
                                            }
                                        </Typography>
                                    </Box>

                                    {RegTypeFields[openParticipantPopup?.registration_type_id]?.fields?.map((item) => {
                                        return <Box mt={1}>
                                            <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>{item.label}</Typography>
                                            <Typography sx={{ fontSize: '12px' }}>{regData && regData[item.key] ? regData[item.key] : "-"}</Typography>
                                        </Box>
                                    })}
                                </>
                                    : "-"
                                }
                            </Box>

                        </Box>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <Box className={commonStyles.customBox}>
                            <Box margin={1}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Annets</Typography>

                                {openParticipantPopup && Array.isArray(openParticipantPopup?.bookingannets) ?
                                    openParticipantPopup?.bookingannets?.map((item) => {
                                        return <Box mt={1}>
                                            <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>{item?.annet_type[0]?.annet_type ?? "-"}</Typography>
                                            <Typography sx={{ fontSize: '12px' }}>{item?.no_of_annets}</Typography>
                                        </Box>
                                    }) : "-"
                                }

                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', flexWrap: 'wrap' }}>
                    <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={handleCloseParticipantPopup}
                        sx={{
                            color: '#EF3826',
                            border: '1px solid #EF3826'
                        }}
                        disabled={updateBookingStatusLoading}
                    >Decline</Button>
                    <Button
                        variant="outlined"
                        startIcon={<DoneIcon />}
                        sx={{
                            color: '#009C2C',
                            border: '1px solid #009C2C'
                        }}
                        disabled={updateBookingStatusLoading}
                        onClick={handleAccept}
                    >Accept</Button>
                </Box>
            </Box>
        </Box>
    }




    return <Container>
        <Modal
            open={Boolean(openParticipantPopup)}
            sx={{ backdropFilter: "blur(2px)" }}
        >
            <Container sx={{ padding: "5px", width: { sm: 800, md: 800, xl: 700, xs: "100%" }, overflow: 'auto' }}>
                {renderParticipantPopup()}
            </Container>
        </Modal>

        <Box mt={1}>
            <Button sx={{ color: '#202224' }} startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/upcoming_events")}
            >
                Back
            </Button>
        </Box>


        <Box sx={{ backgroundColor: '#FFFFFF', width: '100%', borderRadius: "20px" }}>
            <Box sx={{ padding: '15px', textAlign: 'center', borderBottom: '1px solid #A9A9A980' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Participant Management</Typography>
                <Typography sx={{ color: '#6A6A6A', fontSize: '12px' }}>Manage participants for the event</Typography>
            </Box>

            <Grid container mt={1}>
                <Grid xs={12} sm={6}>
                    <Box sx={{ padding: '10px' }}>
                        <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>{eventData?.event[0]?.title}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}> <CalendarIcon style={{ padding: "5px 0px" }} /> &nbsp;
                                <Typography className={commonStyles.InputLabel}>{moment(eventData?.event[0]?.duration_start_date).format("dddd, D MMMM YYYY")}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ClockIcon style={{ padding: "5px 0px" }} /> &nbsp;
                                <Typography className={commonStyles.InputLabel}>{Boolean(eventData?.event[0]?.duration_from_time) && moment(eventData?.event[0]?.duration_from_time, 'HH:mm').format('h:mm A')} -  {Boolean(eventData?.event[0]?.duration_to_time) && moment(eventData?.event[0]?.duration_to_time, 'HH:mm').format('h:mm A')}</Typography>&nbsp;
                                <Typography className={commonStyles.InputLabel}>{findDuration(eventData?.event[0]?.duration_from_time, eventData?.event[0]?.duration_to_time)}</Typography>
                            </Box>
                        </Box>

                    </Box>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Box sx={{ padding: '10px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <LocationIcon />
                            <Box>
                                {EventAddress?.length ? EventAddress?.map((item, i) => (
                                    <Typography
                                        className={commonStyles.InputLabel}>
                                        {item} {EventAddress?.length === i + 1 ? "." : ","}
                                    </Typography>
                                )) : "-"}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ border: "1px solid #A9A9A980" }}>
                <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs example">

                    <Tab label="REQUESTED" onClick={() => { }}     {...a11yProps(0)} sx={{ textTransform: 'none' }} />
                    <Tab label="ACCEPTED" onClick={() => { }}     {...a11yProps(1)} sx={{ textTransform: 'none' }} />
                    <Tab label="ALL" onClick={() => { }}     {...a11yProps(2)} sx={{ textTransform: 'none' }} />

                </Tabs>
            </Box>

            <TabPanel value={value} index={0} style={{ marginTop: '20px', padding: '15px' }}>
                {renderTable()}
            </TabPanel>

            <TabPanel value={value} index={1} style={{ marginTop: '20px', padding: '15px' }}>
                {renderTable()}
            </TabPanel>

            <TabPanel value={value} index={2} style={{ marginTop: '20px', padding: '15px' }}>
                {renderTable()}
            </TabPanel>

        </Box>

    </Container>
}