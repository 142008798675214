import { Box, Grid, Stack, Button, Typography, FormControlLabel, TextField, IconButton, InputAdornment, Select, MenuItem } from "@mui/material";
import useCommonStyles from "../../../Styles/CommonStyles";
import MoneyIcon from "../../../assets/CreateEvent/MoneyIcon.png"
import FreeIcon from "../../../assets/CreateEvent/FreeIcon.svg"
import { ReactComponent as TickIcon } from "../../../assets/CreateEvent/TickIcon.svg"
import { useEffect, useState } from "react";
import CustomSwitch from "../../../Components/common/CustomSwitch";
import { Add as AddIcon } from "@mui/icons-material";
import { ReactComponent as RemoveButtonIcon } from "../../../assets/CreateEvent/RemoveButtonIcon.svg"
import { ReactComponent as ReducebuttonIcon } from "../../../assets/CreateEvent/ReducebuttonIcon.svg"
import { ReactComponent as AddbuttonIcon } from "../../../assets/CreateEvent/AddbuttonIcon.svg"
import { useDispatch, useSelector } from "react-redux";
import { getRegistrationType, getTicketTypes } from "../../../Redux/Slices/CreateEvent/CreateEventSlice";
import moment from "moment";
import ValidationView from "../../../Components/common/ValidationView";
import { hanlePriceOnChange } from "../../../Utils";

export default function Tickets({
    inputData,
    setInputData,
    ticketTypeData,
    setTicketTypeData,
    annetsData,
    setAnnetsData,
    ticketTypeCounts,
    setTicketTypeCounts,
    annetCounts,
    setAnnetCounts,
    setInputError,
    inputError
}) {
    const commonStyles = useCommonStyles();
    const dispatch = useDispatch();




    // const [ticketTypeData, setTicketTypeData] = useState([{ ticket_name: "", price: "", registration_type_id: "" }]); //--> ticket type list
    // const [annetsData, setAnnetsData] = useState([{ annet_type: "", price: "" }]); //--> annets list

    // const [ticketTypeCounts, setTicketTypeCounts] = useState(1);
    // const [annetCounts, setAnnetCounts] = useState(1);



    const getRegistrationTypeData = useSelector(state => state.CreateEventData?.getRegistrationType);
    const getRegistrationTypeLoading = useSelector(state => state.CreateEventData?.getRegistrationTypeLoading);

    useEffect(() => {
        dispatch(getRegistrationType())
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData(prev => ({ ...prev, [name]: value }))
        setInputError(prev => ({ ...prev, [name]: "" }))
    }


    const handleSwitch = (e) => {
        const { name, checked } = e.target;
        setInputData(prev => ({ ...prev, [name]: Number(checked) }))
    }


    const handleRemoveTicketType = (index) => {
        let count = 0
        setTicketTypeData(prev => {
            prev.splice(index, 1)
            count = prev.length
            return [...prev]
        });
        setTicketTypeCounts(count)
    }

    const handleRemoveAnnets = (index) => {
        let count = 0
        setAnnetsData(prev => {
            prev.splice(index, 1)
            count = prev.length
            return [...prev]
        });
        setAnnetCounts(count)
    }

    //--> Registration Types
    const handleNoOfRows = (e, type = null) => {
        const { value } = e.target;
        if (type === "TicketType") {
            const newData = Array.from({ length: value }, () => ({ ticket_name: "", price: "", registration_type_id: "" }));
            setTicketTypeData([...newData])
            setTicketTypeCounts(value)
        } else if (type === "Annets") {
            const newData = Array.from({ length: value }, () => ({ annet_type: "", price: "" }));
            setAnnetsData([...newData])
            setAnnetCounts(value)
        }
    }


    const handleTicketTypeChange = (e, index) => {
        const { name, value } = e.target;

        setTicketTypeData(prev => {
            prev[index][name] = value
            return [...prev]
        })
        setInputError(prev => ({ ...prev, tickets: '' }))
    }

    const handleAnnetChange = (e, index) => {
        const { name, value } = e.target;

        setAnnetsData(prev => {
            prev[index][name] = value
            return [...prev]
        })
        setInputError(prev => ({ ...prev, annets: '' }))
    }



    return <>
        <Grid container columnSpacing={{ xs: 1, sm: 4, md: 7 }} mt={5}>
            <Grid item sm={6}>
                <Box >
                    <Typography className={commonStyles.commonFormTitle}>Booking Date <span style={{ color: 'red' }}>*</span></Typography>
                </Box>



                <Box mt={2}>
                    <Grid container spacing={2}>
                        <Grid item sm={4}>
                            <Typography sx={{ fontSize: '14px' }}>Date</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Box>
                                    <Typography className={commonStyles.InputLabel} sx={{ fontSize: '12px' }}>From</Typography>
                                    <TextField
                                        className={commonStyles.datePicker}
                                        type="date"
                                        name="booking_start_date"
                                        value={inputData?.booking_start_date}
                                        onChange={(e) => handleChange(e)}
                                        inputProps={{ min: moment().format('YYYY-MM-DD') }}
                                        error={Boolean(inputError?.booking_start_date)}
                                        helperText={inputError?.booking_start_date}
                                    />
                                </Box>
                                <Box>
                                    <Typography className={commonStyles.InputLabel} sx={{ fontSize: '12px' }}>To</Typography>
                                    <TextField
                                        type="date"
                                        className={commonStyles.datePicker}
                                        name="booking_end_date"
                                        value={inputData?.booking_end_date}
                                        onChange={(e) => handleChange(e)}
                                        inputProps={{ min: moment(inputData?.booking_start_date).format('YYYY-MM-DD') }}
                                        error={Boolean(inputError?.booking_end_date)}
                                        helperText={inputError?.booking_end_date}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={2}>
                    <Box >
                        <Typography className={commonStyles.commonFormTitle}>Ticket types <span style={{ color: 'red' }}>*</span></Typography>
                    </Box>
                    <Box mt={2}>
                        <Stack
                            direction={"row"}
                            gap={"10px"}
                        >
                            <Typography className={commonStyles.InputLabel} >No of rows</Typography>
                            <Select
                                sx={{
                                    "& .MuiSelect-select.MuiOutlinedInput-input": {
                                        padding: "3.5px 14px"
                                    },
                                    width: '60px'
                                }}
                                onChange={(e) => handleNoOfRows(e, 'TicketType')}
                                value={ticketTypeCounts}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </Stack>
                    </Box>

                    {ticketTypeData.map((item, index) => {
                        return <Box mt={2}>
                            <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                <Box flexGrow={1}>
                                    <Box sx={{ display: "flex", gap: '5px', alignItems: 'center' }}>
                                        {index > 0 ? <IconButton sx={{ padding: '3px' }}
                                            onClick={() => handleRemoveTicketType(index)}
                                        >
                                            <RemoveButtonIcon style={{ height: '20px', width: "20px" }} />
                                        </IconButton> : <Box sx={{ padding: '13px 0px' }}></Box>}
                                        <Typography className={commonStyles.InputLabel}>Ticket name {index + 1} </Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        className={commonStyles.customInput}
                                        sx={{
                                            //  maxWidth: "200px", 
                                            marginTop: "5px"
                                        }}
                                        placeholder="Ticket name"
                                        name="ticket_name"
                                        onChange={(e) => handleTicketTypeChange(e, index)}
                                        value={item.ticket_name}
                                    />
                                </Box>
                                <Box>
                                    <Typography className={commonStyles.InputLabel}>Price</Typography>
                                    <TextField
                                        className={commonStyles.customPriceInput}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                        }}
                                        sx={{ width: "130px", marginTop: "10px" }}
                                        placeholder="0.00"
                                        name="price"
                                        onChange={(e) => hanlePriceOnChange(e, (event) => handleTicketTypeChange(event, index))}
                                        value={item.price}
                                    />
                                </Box>

                                <Box flexGrow={1} sx={{ minWidth: '180px' }}>
                                    <Typography className={commonStyles.InputLabel}>Ticket Type</Typography>
                                    <Select
                                        value={item.registration_type_id}
                                        fullWidth
                                        sx={{
                                            marginTop: "10px",
                                            "& .MuiSelect-select.MuiOutlinedInput-input": {
                                                padding: "6px 14px"
                                            },
                                            backgroundColor: "#fff",
                                            width: { xl: "180px", sm: '180px', xs: '100%' }
                                        }}
                                        name="registration_type_id"
                                        onChange={(e) => handleTicketTypeChange(e, index)}
                                    >
                                        {getRegistrationTypeLoading ? <>
                                            <Box>
                                                <Typography sx={{ textAlign: 'center' }} >Loading...</Typography>
                                            </Box>
                                        </>
                                            : getRegistrationTypeData?.data?.registration_types && Array.isArray(getRegistrationTypeData?.data?.registration_types) &&
                                            getRegistrationTypeData?.data?.registration_types?.map((hostData, index) => {
                                                return <MenuItem value={hostData.id} >
                                                    <Typography sx={{ fontSize: '14px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                        {hostData.name}
                                                    </Typography>
                                                </MenuItem>
                                            })
                                        }

                                    </Select>
                                </Box>
                            </Box>
                        </Box>
                    })}

                    <ValidationView sx={{ fontSize: '12px' }}>{inputError?.tickets}</ValidationView>
                </Box>

                <Box mt={3}>
                    <Box >
                        <Typography className={commonStyles.commonFormTitle}>Annets <span style={{ color: 'red' }}>*</span></Typography>
                    </Box>
                    <Box mt={2}>
                        <Stack
                            direction={"row"}
                            gap={"10px"}
                        >
                            <Typography className={commonStyles.InputLabel} >No of rows</Typography>
                            <Select
                                sx={{
                                    "& .MuiSelect-select.MuiOutlinedInput-input": {
                                        padding: "3.5px 14px"
                                    },
                                    width: '60px'
                                }}
                                onChange={(e) => handleNoOfRows(e, 'Annets')}
                                value={annetCounts}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                {/* <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem> */}
                            </Select>
                        </Stack>
                    </Box>

                    {annetsData.map((item, index) => {
                        return <Box mt={2}>
                            <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                <Box >
                                    <Box sx={{ display: "flex", gap: '5px', alignItems: 'center' }}>
                                        {index > 0 ? <IconButton sx={{ padding: '3px' }}
                                            onClick={() => handleRemoveAnnets(index)}
                                        >
                                            <RemoveButtonIcon style={{ height: '20px', width: "20px" }} />
                                        </IconButton> : <Box sx={{ padding: '13px 0px' }}></Box>}
                                        <Typography className={commonStyles.InputLabel}>Annet type {index + 1} </Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        className={commonStyles.customInput}
                                        sx={{
                                            //  maxWidth: "200px", 
                                            marginTop: "5px"
                                        }}
                                        placeholder="Type"
                                        name="annet_type"
                                        onChange={(e) => handleAnnetChange(e, index)}
                                        value={item.annet_type}
                                    />
                                </Box>
                                <Box>
                                    <Typography className={commonStyles.InputLabel}>Price</Typography>
                                    <TextField
                                        className={commonStyles.customPriceInput}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                        }}
                                        sx={{ width: "130px", marginTop: "10px" }}
                                        placeholder="0.00"
                                        name="price"
                                        onChange={(e) => hanlePriceOnChange(e, (event) => handleAnnetChange(event, index))}
                                        value={item.price}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    })}

                    <ValidationView sx={{ fontSize: '12px' }}>{inputError?.annets}</ValidationView>
                </Box>

            </Grid>

            <Grid item sm={6}>
                <Box mt={2}>
                    <Typography className={commonStyles.commonFormTitle}>Host approval <span style={{ color: 'red' }}>*</span></Typography>

                    <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px', mt: 1 }}>
                        <FormControlLabel
                            sx={{
                                "& .MuiTypography-root": {
                                    fontSize: "13px"
                                },
                                "&.MuiFormControlLabel-root": {
                                    gap: "10px",
                                    marginLeft: '0'
                                }
                            }}
                            name="is_approved"
                            onChange={handleSwitch}
                            control={<CustomSwitch checked={Boolean(inputData?.is_approved)} />}
                            label="Approval Required"
                            labelPlacement="end"
                        />

                        <Typography sx={{ fontSize: "12px" }}>
                            Confirmation will be sent after host approves the booking.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid >
    </>
}