import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
        primary: {
            main: '#0052cc',
        },
        secondary: {
            main: '#edf2ff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-containedPrimary': {
                        // backgroundColor: '#1890FF',
                    },
                    "&.MuiButtonBase-root.MuiButton-root.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.26)",
                        boxShadow: "none",
                        backgroundColor: "rgba(0, 0, 0, 0.12)!important"
                    },
                    textTransform: "none"
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    "& .MuiBackdrop-root": {
                        // backgroundColor: "transparent",
                    },
                    // height: "100%",
                    // width: "100%",
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    "& .MuiContainer-root": {
                        // width: 400,
                        // backgroundColor: "#ffffff",
                        // borderRadius: "10px",
                        // boxShadow: 24,
                        // border: '0.5px solid #d3d3d3'
                    }


                }
            }
        }
    }
});

theme = responsiveFontSizes(theme);

export default theme;
