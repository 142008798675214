import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import useCommonStyles from '../../../Styles/CommonStyles';

const SearchLocation = ({ setCoordinates, value }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const commonStyles = useCommonStyles()

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      return;
    }

    const fetchSuggestions = async () => {
      try {
        const response = await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + encodeURIComponent(inputValue) + '.json', {
          params: {
            access_token: 'sk.eyJ1Ijoic2FuY2hhaXBzdHMiLCJhIjoiY2xraHRnbXczMDRtMzNwdDhkYWE2cmN3biJ9.2l9UX0XHqeVbq-pa_ZIHiQ',
            autocomplete: true,
            limit: 5,
          },
        });

        setOptions(
          response.data.features.map((feature) => ({
            label: feature.place_name,
            lat: feature.center[1],
            lng: feature.center[0],
          }))
        );
      } catch (error) {
        console.error('Error fetching location suggestions:', error);
      }
    };

    fetchSuggestions();
  }, [inputValue]);

  const handlePlaceSelect = (event, value) => {
    if (value) {
      setCoordinates({
        lat: value.lat,
        lng: value.lng,
        address: value.label
      });
    } else {
      setCoordinates({});
    }
  };



  let selectedValue = value ? { label: value ?? null } : null;

  return (
    <Autocomplete
      id="place-search"
      sx={{
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          padding: '5px',
        },
      }}
      value={selectedValue}
      inputValue={inputValue}
      className={commonStyles.customInput}
      options={options}
      getOptionLabel={(option) => option?.label ?? ""}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={handlePlaceSelect}
      classes={{
        noOptions: commonStyles.dropDownLabel,
        loading: commonStyles.dropDownLabel,
        listbox: commonStyles.dropDownLabel,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={commonStyles.customInput}
          placeholder="Search Location"
          variant="outlined"
          fullWidth
        />
      )}
    />

  );
};

export default SearchLocation;
