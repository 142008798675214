import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';



export default function MuiSelect({ setCategoryValue, noPropBtn, options = [], onChange = () => { }, value, name, currentPage, labelKey = 'label', valueKey = '', ...props }) {
    const [inputValue, setInputValue] = useState('');
    let Event = { target: { name: name, value: null, original: null } }

    return (
        <>
            <Autocomplete
                fullWidth
                disabled={props?.disabled}
                id={props?.id}
                sx={props?.sx}
                className={props?.className}
                style={props?.style}
                popupIcon={<ExpandMoreIcon />}
                value={value}
                inputValue={inputValue}
                getOptionDisabled={(option) => Array.isArray(options) ? !!options.find(element => element === option && element.disabled) : false}
                onChange={(event, newValue) => {
                    if (valueKey && newValue) { Event.target.value = newValue[valueKey]; Event.target.original = newValue }
                    else { Event.target.value = newValue; Event.target.original = newValue; }
                    onChange(Event)
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                    if (setCategoryValue) {
                        setCategoryValue(newInputValue); // get searched category name and show this in category add poopup after click add new category btn
                    }
                }} // handles typing
                options={Array.isArray(options) ? options : []}
                getOptionLabel={(option) => {
                    if (labelKey && valueKey) {
                        if (typeof option === "object") {
                            return option[labelKey]
                        } else {
                            const findIndex = (Array.isArray(options) && options?.length) ? options.findIndex(item => item[valueKey] == option) : -1
                            if (findIndex > -1) {
                                const obj = options[findIndex]
                                return typeof obj === "object" ? obj[labelKey] : ''
                            } else return ''
                        }
                    } else return option
                    return ''
                }}
                isOptionEqualToValue={(option, value) => {
                    if (valueKey && option && typeof option === "object" && option[labelKey] && value) {
                        return option[valueKey] == value
                    } else if (option && typeof option === "object" && option[labelKey] && value && typeof value === "object") {
                        return option[labelKey] == value[labelKey]
                    } else return false
                }}
                noOptionsText={noPropBtn ? noPropBtn : 'No Options'}
                renderInput={params => (
                    <TextField {...params} inputProps={{ ...params?.inputProps, ...props?.inputProps }} style={props?.style} placeholder={props?.placeholder} variant="outlined" size="medium" />
                )}
                {...props}
            />
        </>
    );
}

MuiSelect.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};