import { Route, Routes } from "react-router-dom";
import Header from "../Components/Header";
import NotFound from "../Components/PageNotFound";
import UpcomingEvents from "../Pages/Host/UpcomingEvents";
import LiveEvents from "../Pages/Host/LiveEvents";
import ParticipantManagement from "../Pages/Host/UpcomingEvents/ParticipantManagement";

export default function HostRoutes(params) {
    return <Routes>
        <Route path='/upcoming_events' element={<Header><UpcomingEvents /></Header>} />
        <Route path='/live_events' element={<Header><LiveEvents /></Header>} />
        <Route path='/participants_management/:id' element={<Header><ParticipantManagement /></Header>} />
        <Route path="/*" element={<Header><NotFound /></Header>} />
    </Routes>
}