import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import LoginImage from "../../assets/Login/LoginImage.png"
import LeftSideLogo from "../../assets/Login/LeftSideLogo.png"
import { ReactComponent as SupportIcon } from "../../assets/Login/SupportIcon.svg";
import { Mail as MailIcon, Login as LoginIcon, Password as PasswordIcon } from '@mui/icons-material';
import LogoImage from "../../assets/Login/LogoImage.png";
import { login, setLoginSliceToNull } from "../../Redux/Slices/Login/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../../Redux/Slices/common/SnackbarSlice";


export default function LandingPage(params) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginData = useSelector(state => state.Login);


    const [inputData, setInputData] = useState({
        email: "",
        password: ""
    });
    const [inputErrors, setInputError] = useState({
        email: "",
        password: ""
    });


    useEffect(() => {

        if (loginData?.loginStatus?.data?.token) {
            localStorage.setItem("token", loginData?.loginStatus?.data?.token?.access_token);
            localStorage.setItem("user_details", JSON.stringify(loginData?.loginStatus?.data?.user_details));

            setTimeout(() => navigate("/upcoming_events"), 400);
            dispatch(setLoginSliceToNull())

        } else if (loginData?.loginStatus?.data?.validation_error &&
            typeof loginData?.loginStatus?.data?.validation_error === "object"
        ) {

            setInputError({ ...loginData?.loginStatus?.data?.validation_error })
            dispatch(setLoginSliceToNull());

        } else if (loginData?.loginStatus?.error) {

            dispatch(openSnackbar({ message: loginData?.loginStatus?.toast, severity: "error" }))
            dispatch(setLoginSliceToNull())
        }

    }, [loginData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData(prev => ({
            ...prev,
            [name]: value
        }))
        setInputError(prev => ({ ...prev, [name]: false }))
    }

    const validate = () => {
        let valid = true;

        if (!inputData.email) {
            setInputError(prev => ({ ...prev, email: 'Email is required' }))
            valid = false
        } else {
            setInputError({ email: '' })
        }

        if (!inputData.password) {
            setInputError(prev => ({ ...prev, password: 'Password is required' }))
            valid = false
        } else {
            setInputError({ password: '' })
        }

        return valid
    }

    const handleLogin = () => {
        const isValid = validate();
        if (!isValid) return;

        dispatch(login({
            email: inputData.email,
            password: inputData.password,
        }))
    }



    return <Fragment>
        <Box sx={{ display: 'flex', height: "100vh", overflow: "auto" }}>
            <Box sx={{ width: "100%", display: { md: "block" } }}>
                <Box sx={{ backgroundImage: `url(${LoginImage})`, backgroundPosition: "center", position: 'relative', backgroundSize: "cover", height: "100%", width: "100%", backgroundRepeat: "no-repeat" }}>
                    <Box sx={{ display: { xs: "none", sm: 'block', md: 'block' }, padding: { sm: "25px 40px", md: '25px 40px', xl: "25px 40px" } }}>
                        <img src={LeftSideLogo} style={{ height: 34, width: 70 }} />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: "calc(100% - 200px)"
                        }}
                    >
                        <Box sx={{
                            minHeight: 300,
                            width: 350,
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0px 4px 10px 0px #FFFFFF40",
                            borderRadius: '10px',
                            padding: '25px',
                            display: 'flex',
                            // justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',

                        }}>
                            <img src={LogoImage} style={{}} />

                            <Box width={300}>
                                <Box mt={3}>
                                    <Typography sx={{ fontSize: '12px' }}>Email</Typography>
                                    <TextField
                                        fullWidth
                                        placeholder=""
                                        name="email"
                                        onChange={handleChange}
                                        error={Boolean(inputErrors?.email)}
                                        value={inputData.email}
                                        helperText={inputErrors?.email ?? ""}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <MailIcon />
                                            </InputAdornment>
                                        }}
                                    />
                                </Box>
                                <Box mt={3}>
                                    <Typography sx={{ fontSize: '12px' }}>Password</Typography>
                                    <TextField
                                        fullWidth
                                        placeholder=""
                                        name="password"
                                        onChange={handleChange}
                                        error={Boolean(inputErrors?.password)}
                                        value={inputData.password}
                                        helperText={inputErrors?.password ?? ""}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        }}
                                    />
                                </Box>

                                <Box mt={3}>
                                    <Button
                                        onClick={handleLogin}
                                        fullWidth
                                        sx={{
                                            backgroundColor: "#0B6BC2",
                                            color: '#FFFFFF',
                                            "&:hover": {
                                                backgroundColor: '#0B6BC2'
                                            },
                                            borderRadius: '50px'
                                        }}
                                        startIcon={<LoginIcon />}
                                        disabled={loginData.loading}
                                    >Login {loginData.loading ? <>&nbsp; <CircularProgress style={{ height: '20px', width: '20px' }} /></> : null}</Button>
                                </Box>
                            </Box>

                        </Box>
                    </Box>


                    <Box sx={{ display: 'flex', justifyContent: "flex-end", padding: '10px' }}>
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            boxShadow: "0px 4px 10px 0px #FFFFFF40",
                            borderRadius: '10px',
                            height: '70px',
                            width: '190px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0px 10px'
                        }}>
                            <Box>
                                <Typography sx={{ fontSize: '12px' }}>Having difficulty logging in?</Typography>
                                <Box sx={{ display: 'inline-flex' }}>
                                    <Typography sx={{ color: '#006198', fontWeight: 700, fontSize: '14px' }}>Contact</Typography>
                                    &nbsp; <Typography sx={{ fontWeight: 700, fontSize: '12px' }}>our support</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <SupportIcon />
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>
    </Fragment >

}