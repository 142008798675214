import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button, CircularProgress, Container, Grid, IconButton, Modal, Paper, Stack, Typography } from "@mui/material";
import { ArrowForwardIos as ArrowForwardIosIcon, Close as CloseIcon, Circle as CircleIcon, QrCodeScanner as QrCodeScannerIcon } from '@mui/icons-material';
import moment from "moment";
import { getHostLiveEvents } from "../../../Redux/Slices/Host/LiveEvents/HostLiveEventSlice";
import useCommonStyles from "../../../Styles/CommonStyles";
import { ReactComponent as CalendarIcon } from "../../../assets/EventPreview/CalendarIcon.svg";
import { ReactComponent as ClockIcon } from "../../../assets/EventPreview/ClockIcon.svg";
import QrCodeScanner from "./QrCodeScanner";


export default function LiveEventList(params) {
    const dispatch = useDispatch();
    const commonStyles = useCommonStyles();

    const [openScanPopup, setOpenScanPopup] = useState(false)
    const [openParticiantPopup, setOpenParticiantPopup] = useState(false);


    const LiveEventsData = useSelector(state => state?.HostLiveEventData?.LiveEvents)
    const LiveEventsLoading = useSelector(state => state?.HostLiveEventData?.LiveEventsLoading);

    const getParticipantsListByBookingIdData = useSelector(state => state?.HostLiveEventData?.getParticipantsListByBookingId);

    useEffect(() => {
        dispatch(getHostLiveEvents({ current_date: moment().format('YYYY-MM-DD') }))
    }, [])

    const findDuration = (startTime, endTime) => {

        if (startTime && endTime) {
            let start = moment(startTime, 'HH:mm');
            let end = moment(endTime, 'HH:mm');



            // Calculate the duration
            let duration = moment.duration(end.diff(start));

            // Get the duration in hours and minutes
            let hours = Math.floor(duration.asHours());
            let minutes = duration.minutes();

            return `${hours}hours ${Number(minutes) > 0 ? `${minutes}minutes` : ''}`
        } else return "-"
    }

    const handleClickScan = (event) => {
        setOpenScanPopup(true)
    }
    const handleCloseScanPopup = () => {
        setOpenScanPopup(false)
    }

    const handleScan = (data) => {
        setOpenScanPopup(false);
        setOpenParticiantPopup(data)
    }

    const handleCloseParticipantDetails = () => {
        setOpenParticiantPopup(false)
    }

    const renderScanner = () => {
        return <Box sx={{ borderRadius: '10px', display: 'flex', flexDirection: 'column', backgroundColor: '#fff', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 15px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Scan QR</Typography>

                <IconButton sx={{}} onClick={() => handleCloseScanPopup()}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{
                flexGrow: 1, padding: '20px',
                borderRadius: '0px 0px 10px 10px',
                borderTop: '1px solid #A9A9A980',
                backgroundColor: '#F5F6FA'
            }}>
                <Typography sx={{ textAlign: 'center' }}>Camera View</Typography>
                <Box >
                    <QrCodeScanner
                        handleScan={handleScan}
                    />
                </Box>
                <Typography sx={{ textAlign: 'center' }}>Place QR Infront of camera</Typography>
            </Box>
        </Box>
    }



    const renderParticipantDetails = () => {
        return <Box sx={{ borderRadius: '10px', display: 'flex', flexDirection: 'column', backgroundColor: '#fff', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 15px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Participant</Typography>

                <IconButton sx={{}} onClick={() => handleCloseParticipantDetails()}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{
                flexGrow: 1,
                padding: '20px',
                borderRadius: '0px 0px 10px 10px',
                borderTop: '1px solid #A9A9A980',
                backgroundColor: '#F5F6FA'
            }}>
                Under construction
            </Box>
        </Box>
    }

    return <>
        <Modal
            open={Boolean(openScanPopup)}
            sx={{ backdropFilter: "blur(2px)" }}
        >
            <Container style={{ padding: "5px", width: 400, height: '500px', overflow: 'auto' }}>
                {renderScanner()}
            </Container>
        </Modal>

        <Modal
            open={Boolean(openParticiantPopup)}
            sx={{ backdropFilter: "blur(2px)" }}
        >
            <Container style={{ padding: "5px", width: 400, height: '500px', overflow: 'auto' }}>
                {renderParticipantDetails()}
            </Container>
        </Modal>

        <Stack
            gap={"10px"}
        >
            {LiveEventsLoading ?
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress sx={{}} />
                </Box> : Array.isArray(LiveEventsData?.data?.events) &&
                    LiveEventsData?.data?.events?.length ?
                    LiveEventsData?.data?.events?.map((item) => {

                        return <Grid container component={Paper} elevation={0}>
                            <Grid sm={8} xs={12}>
                                <Box sx={{ padding: '15px' }}>
                                    <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#2D2C3C' }}>{item?.event[0]?.title}</Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            sx={{ fontWeight: '600', fontSize: '14px', color: '#2D2C3C' }}
                                        >
                                            {item?.event[0]?.rotarian?.rotarian_district ?? "-"}
                                        </Typography> &nbsp;
                                        <CircleIcon sx={{ fontSize: '10px' }} />
                                        &nbsp;
                                        <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#2D2C3C' }}>
                                            {item?.event[0]?.rotarian.rotarian_id}
                                        </Typography>
                                    </Box>

                                    <Box mt={1}>
                                        <Typography className={commonStyles.commonFormTitle}>Date & Time</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}> <CalendarIcon style={{ padding: "5px 0px" }} /> &nbsp;
                                            <Typography className={commonStyles.InputLabel}>{item?.event[0]?.duration_start_date ? moment(item?.event[0]?.duration_start_date).format('dddd, D MMMM YYYY') : null}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <ClockIcon style={{ padding: "5px 0px" }} /> &nbsp;
                                            <Typography className={commonStyles.InputLabel}>{Boolean(item?.event[0]?.duration_from_time) && moment(item?.event[0]?.duration_from_time, 'HH:mm').format('h:mm A')} -  {Boolean(item?.event[0]?.duration_to_time) && moment(item?.event[0]?.duration_to_time, 'HH:mm').format('h:mm A')}</Typography>&nbsp;
                                            <Typography className={commonStyles.InputLabel}>{findDuration(item?.event[0]?.duration_from_time, item?.event[0]?.duration_to_time)}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid sm={4} xs={12}>
                                <Box sx={{ padding: '15px', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                    <Box sx={{ backgroundColor: '#FCD7D4', padding: '5px', borderRadius: '8px', color: '#EF3826' }}>
                                        <Typography sx={{ display: 'inline-flex', alignItems: 'center', gap: '4px', fontWeight: '600', fontSize: '12px' }}><CircleIcon sx={{ fontSize: '8px' }} /> Live</Typography>
                                    </Box>

                                    <Button variant="contained" onClick={() => handleClickScan(item?.event[0])} startIcon={<QrCodeScannerIcon />}>Scan</Button>
                                </Box>
                            </Grid>
                        </Grid>

                    }) : <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    >
                        <Typography>No data found</Typography>
                    </Box>
            }


        </Stack >
    </>
}