export const focusError = (id) => {
    const element = document.getElementById(id)

    if (element) {
        // console.log(element.scrollIntoView(), 'element');       
        element.focus()
    }
}

export const scrollIntoViewError = (id) => {
    const element = document.getElementById(id)
    if (element) {
        element.scrollIntoView()
    }
}

export const hanlePriceOnChange = (e, func) => {

    if (e?.target && e.target.hasOwnProperty("value")) {
        if (e.target.value.match(/^(\-?\d{1,12}((,\d{3})*))(\.\d{0,3})?$/) || e.target.value == "") {
            func(e)
        }
    }

}

export function isJSONString(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}


export const getUserDetails = () => {
    const data = localStorage.getItem("user_details")

    if (data && isJSONString(data)) {
        return JSON.parse(data)
    } else return {}
}
