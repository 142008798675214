import { Route, Routes } from "react-router-dom";
import Header from "../Components/Header";
import UpcomingEvents from "../Pages/Admin/UpcomingEvents";
import CreateEvent from "../Pages/Admin/CreateEvent";
import NotFound from "../Components/PageNotFound";

export default function AdminRoutes(params) {
    return <Routes>
        <Route path='/upcoming_events' element={<Header><UpcomingEvents /></Header>} />
        <Route path='/create_event' element={<Header openBar={false} ><CreateEvent /></Header>} />
        <Route path='/edit_event/:id' element={<Header openBar={false} ><CreateEvent /></Header>} />
        <Route path="/*" element={<Header><NotFound /></Header>} />
    </Routes>
}