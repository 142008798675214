import { Box, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import useCommonStyles from "../../../Styles/CommonStyles";
import { ReactComponent as LocationIcon } from "../../../assets/CreateEvent/LocationIcon.svg"
import MapComponent from "./MapComponent";
import { useEffect, useState } from "react";
import SearchLocation from "./SearchLocation";
import CustomSwitch from "../../../Components/common/CustomSwitch";
import { useDispatch, useSelector } from "react-redux";
import { getAmenities, getClubNames, getFoodTypes } from "../../../Redux/Slices/CreateEvent/CreateEventSlice";
import MuiSelect from "../../../Components/common/MuiSelect";
import ValidationView from "../../../Components/common/ValidationView";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, selected) {
    return {
        fontWeight:
            selected.indexOf(name) === -1
                ? 400
                : 600,
    };
}

const initialState = {
    title: "",
    zone: "",
    club_name: "",
    latitude: "",
    longitude: "",
    duration: "",
    is_food_available: '',
    food_type_id: [],
    duration_start_date: "",
    duration_end_date: "",
    duration_from_time: "",
    duration_to_time: "",
}

export default function EssentialDetails({
    searchLocation,
    setSearchLocation,
    inputData,
    inputError,
    setInputError,
    setInputData,
    selectedAmenities,
    setSelectedAmenities
}) {
    const commonStyles = useCommonStyles();
    const dispatch = useDispatch();

    // const [searchLocation, setSearchLocation] = useState({})
    // const [inputData, setInputData] = useState(initialState);
    // const [selectedAmenities, setSelectedAmenities] = useState([])

    const [clubNameData, setClubNameData] = useState([])


    const getFoodTypesData = useSelector(state => state.CreateEventData?.getFoodTypes);
    const getClubNamesData = useSelector(state => state.CreateEventData?.getClubNames);
    const getAmenitiesData = useSelector(state => state.CreateEventData?.getAmenities);

    const getAmenitiesLoading = useSelector(state => state.CreateEventData?.getAmenitiesLoading);



    useEffect(() => {
        dispatch(getFoodTypes())
        dispatch(getClubNames())
        dispatch(getAmenities())
    }, [])


    useEffect(() => {
        let newData = [];
        if (getClubNamesData?.data?.club_name && typeof getClubNamesData?.data?.club_name === "object") {
            for (const key in getClubNamesData?.data?.club_name) {
                newData.push({
                    value: key,
                    club_name: getClubNamesData?.data?.club_name[key]
                })
            }
        }
        setClubNameData(newData);

    }, [getClubNamesData])



    const handleSwitch = (e) => {
        const { name, checked } = e.target;

        setInputData(prev => ({
            ...prev,
            [name]: Number(checked),
            food_type_id: (name === "is_food_available") ? [] : prev?.food_type_id
        }))
    }

    const handleCheckBox = (e) => {
        const { name, checked, value } = e.target;

        setInputData(prev => {
            const index = prev[name]?.indexOf(Number(value))

            if (index > -1) {
                prev[name]?.splice(index, 1)
            } else {
                prev[name]?.push(Number(value))
            }
            return { ...prev }
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData(prev => ({ ...prev, [name]: value }))
        setInputError(prev => ({ ...prev, [name]: false }))
    }

    const handleAmenities = (e) => {
        const { value } = e.target;

        // let newValue = typeof value === 'string' ? value.split(',') : value
        // for (const id in newValue) {
        //     const finded =   
        // }
        setSelectedAmenities(typeof value === 'string' ? value.split(',') : value)
        setInputError(prev => ({ ...prev, amenities_type_id: "" }))
    }




    return <>
        <Grid container columnSpacing={{ xs: 1, sm: 4, md: 7 }} mt={5}>
            <Grid item sm={5}>
                <Box >
                    <Typography className={commonStyles.commonFormTitle}>Event Details*</Typography>
                </Box>

                <Box mt={3}>
                    <Typography className={commonStyles.InputLabel} >Event Title</Typography>
                    <TextField
                        className={commonStyles.customInput}
                        fullWidth
                        placeholder="Title"
                        name="title"
                        value={inputData?.title}
                        onChange={handleChange}
                        error={Boolean(inputError?.title)}
                        helperText={inputError?.title}
                    />
                </Box>

                <Box mt={2}>
                    <Typography className={commonStyles.InputLabel} >Zone</Typography>
                    <TextField
                        className={commonStyles.customInput}
                        fullWidth
                        placeholder="Zone"
                        name="zone"
                        value={inputData?.zone}
                        onChange={handleChange}
                        error={Boolean(inputError?.zone)}
                        helperText={inputError?.zone}
                    />
                </Box>

                <Box mt={2}>
                    <Typography className={commonStyles.InputLabel} >Club name</Typography>
                    <MuiSelect
                        className={commonStyles.commonAutoComplete}
                        classes={{
                            noOptions: commonStyles.dropDownLabel,
                            loading: commonStyles.dropDownLabel,
                            listbox: commonStyles.dropDownLabel
                        }}
                        onChange={handleChange}
                        options={clubNameData}
                        name="club_name"
                        value={inputData?.club_name ?? ""}
                        valueKey="value"
                        labelKey='club_name'
                        placeholder="Select Club Name"
                        id="club_name"
                    />
                    <ValidationView sx={{ fontSize: '12px' }}>{inputError?.club_name}</ValidationView>
                </Box>

                <Box mt={"20px"}>
                    <Typography className={commonStyles.commonFormTitle} >Location*</Typography>

                    <Box mt={2}>
                        <Typography className={commonStyles.InputLabel} >Search location</Typography>
                        <SearchLocation
                            setCoordinates={(data) => {

                                setInputData(prev => ({
                                    ...prev,
                                    latitude: data.lat,
                                    longitude: data?.lng,
                                    address: data?.address
                                }));
                                setSearchLocation(data);
                                setInputError(prev => ({ ...prev, latitude: "" }))
                            }}
                            value={inputData?.address}
                        />
                        <ValidationView sx={{ fontSize: '12px' }}>{inputError?.latitude}</ValidationView>
                    </Box>
                    <Box mt={2}>
                        <Typography sx={{ display: 'inline-flex', alignItems: 'center', color: '#2D2C3C', fontSize: '16px', fontStyle: 'italic' }}><LocationIcon /> Location details</Typography>
                    </Box>

                    <Box mt={2} sx={{
                        height: 250,
                        width: 400
                    }}>
                        <MapComponent coordinates={searchLocation} />
                    </Box>

                </Box>


            </Grid>
            <Grid item sm={7}>
                <Box >
                    <Typography className={commonStyles.commonFormTitle} >Date & Time*</Typography>
                </Box>

                <Box mt={3} >
                    <Grid container spacing={2} >
                        <>
                            <Grid item sm={4} xs={4}>
                                <Typography className={commonStyles.InputLabel}>Duration</Typography>
                            </Grid>
                            <Grid item sm={8} xs={8}>
                                <FormControlLabel
                                    sx={{
                                        "& .MuiTypography-root": {
                                            fontSize: "13px"
                                        },
                                        "&.MuiFormControlLabel-root": {
                                            gap: "10px"
                                        }
                                    }}
                                    name="duration"
                                    onChange={handleSwitch}
                                    control={<CustomSwitch checked={Boolean(inputData?.duration)} />}
                                    label="Multiple days"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </>

                        <>
                            <Grid item sm={4} xs={4}>
                                <Typography className={commonStyles.InputLabel}>Date</Typography>
                            </Grid>
                            <Grid item sm={8} xs={8}>
                                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <TextField
                                        type="date"
                                        className={commonStyles.datePicker}
                                        name="duration_start_date"
                                        onChange={handleChange}
                                        value={inputData?.duration_start_date}
                                        error={Boolean(inputError?.duration_start_date)}
                                        inputProps={{ min: moment().format('YYYY-MM-DD') }}
                                    />
                                    <Typography sx={{ padding: '10px', fontSize: '12px' }}>To</Typography>

                                    <TextField
                                        type="date"
                                        className={commonStyles.datePicker}
                                        name="duration_end_date"
                                        onChange={handleChange}
                                        value={inputData?.duration_end_date}
                                        inputProps={{ min: moment(inputData.duration_start_date).format("YYYY-MM-DD") }}
                                    />
                                </Box>
                                <Box sx={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
                                    <ValidationView sx={{ fontSize: '12px' }}>{inputError?.duration_start_date}</ValidationView>
                                    <ValidationView sx={{ fontSize: '12px' }}>{inputError?.duration_end_date}</ValidationView>
                                </Box>
                            </Grid>
                        </>

                        <>
                            <Grid item sm={4} xs={4}>
                                <Typography className={commonStyles.InputLabel}>Time</Typography>
                            </Grid>
                            <Grid item sm={8} xs={8}>
                                <Box sx={{ display: 'flex', gap: '20px' }}>
                                    <Box>
                                        <Typography className={commonStyles.InputLabel} >Start Time</Typography>
                                        <TextField
                                            type="time"
                                            className={commonStyles.datePicker}
                                            name="duration_from_time"
                                            onChange={handleChange}
                                            value={inputData?.duration_from_time}
                                        />
                                    </Box>

                                    <Box>
                                        <Typography className={commonStyles.InputLabel} >End Time</Typography>
                                        <TextField
                                            type="time"
                                            className={commonStyles.datePicker}
                                            name="duration_to_time"
                                            onChange={handleChange}
                                            value={inputData?.duration_to_time}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
                                    <ValidationView sx={{ fontSize: '12px' }}>{inputError?.duration_start_date}</ValidationView>
                                    <ValidationView sx={{ fontSize: '12px' }}>{inputError?.duration_end_date}</ValidationView>
                                </Box>
                            </Grid>
                        </>
                    </Grid>
                </Box>

                <Box mt={2}>
                    <Box >
                        <Typography className={commonStyles.commonFormTitle} >Amenities*</Typography>
                    </Box>

                    <Box mt={2}>
                        <Typography className={commonStyles.InputLabel} >Amenity</Typography>

                        <Select
                            sx={{
                                "& .MuiSelect-select.MuiOutlinedInput-input": {
                                    padding: "6.5px 14px"
                                },
                                backgroundColor: "#fff"
                            }}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            fullWidth
                            value={selectedAmenities}
                            onChange={handleAmenities}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => {
                                        const selectedAmenity = getAmenitiesData?.data?.amenities?.find(item => item.id === value);
                                        return <Chip key={selectedAmenity?.id} label={selectedAmenity?.name} />
                                    })}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {getAmenitiesLoading ?
                                <MenuItem

                                >
                                    <CircularProgress />
                                </MenuItem>
                                :

                                Array.isArray(getAmenitiesData?.data?.amenities) && getAmenitiesData?.data?.amenities.map((item) => (
                                    <MenuItem
                                        key={item.key}
                                        value={item.id}
                                        style={getStyles(item.id, selectedAmenities)}
                                    >
                                        <Typography sx={{ fontSize: '12px', }}>{item.name}</Typography>
                                    </MenuItem>
                                ))}
                        </Select>
                        <ValidationView sx={{ fontSize: '12px' }}>{inputError?.amenities_type_id}</ValidationView>
                    </Box>

                    <Box mt={2}>
                        <Typography className={commonStyles.InputLabel} >Food availability</Typography>
                        <FormControlLabel
                            sx={{
                                "& .MuiTypography-root": {
                                    fontSize: "13px"
                                },
                                "&.MuiFormControlLabel-root": {
                                    gap: "10px",
                                    marginLeft: '0'
                                }
                            }}
                            name="is_food_available"
                            onChange={handleSwitch}
                            control={<CustomSwitch checked={Boolean(inputData?.is_food_available)} />}
                            label="Yes"
                            labelPlacement="end"
                        />
                    </Box>

                    <Box mt={2} className={[Boolean(inputData?.is_food_available) ? "" : 'elem-disable']} >
                        <Typography className={commonStyles.InputLabel} >Food Provided</Typography>
                        <FormGroup>
                            {getFoodTypesData?.data?.food_type && Array.isArray(getFoodTypesData?.data?.food_type) &&
                                getFoodTypesData?.data?.food_type?.map((item, index) => {
                                    return <FormControlLabel name="food_type_id" value={item.id} onChange={handleCheckBox} control={<Checkbox checked={inputData?.food_type_id?.includes(item?.id)} />} label={item?.name} />
                                })
                            }
                        </FormGroup>
                    </Box>
                </Box>
            </Grid>
        </Grid >
    </>
}