import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../AxiosInstance";
import { getUserDetails } from "../../../../Utils";

const initialState = {
    LiveEventsLoading: false,
    LiveEvents: null,
    getParticipantsListByBookingId: null,
    getParticipantsListByBookingIdLoading: false,
}

const userDetails = getUserDetails()

export const getHostLiveEvents = createAsyncThunk('getHostLiveEvents', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/${userDetails?.host_id}/event_details_by_host_id?current_date=${data?.current_date}`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getParticipantsListByBookingId = createAsyncThunk('getParticipantsListByBookingId', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/${data?.booking_id}/booking/participants_list`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

const HostLiveEvents = createSlice({
    name: 'HostLiveEvents',
    initialState,
    reducers: {
        setHostLiveEventsToNull: (state) => {
            state.LiveEvents = null
        },
        setGetParticipantsListByBookingIdToNull: (state) => {
            state.getParticipantsListByBookingId = null
        },

    },
    extraReducers: (builders) => {
        //upcomingEvent
        builders.addCase(getHostLiveEvents.fulfilled, (state, action) => {
            state.LiveEvents = action.payload.data
            state.LiveEventsLoading = false
        })
        builders.addCase(getHostLiveEvents.pending, (state, action) => {
            state.LiveEventsLoading = true
        })
        builders.addCase(getHostLiveEvents.rejected, (state, action) => {
            state.LiveEvents = action?.payload?.response?.data
            state.LiveEventsLoading = false
        })

        //getParticipantsListByBookingId
        builders.addCase(getParticipantsListByBookingId.fulfilled, (state, action) => {
            state.getParticipantsListByBookingId = action.payload.data
            state.getParticipantsListByBookingIdLoading = false
        })
        builders.addCase(getParticipantsListByBookingId.pending, (state, action) => {
            state.getParticipantsListByBookingIdLoading = true
        })
        builders.addCase(getParticipantsListByBookingId.rejected, (state, action) => {
            state.getParticipantsListByBookingId = action?.payload?.response?.data
            state.getParticipantsListByBookingIdLoading = false
        })

    }
})

export const { setHostLiveEventsToNull, setGetParticipantsListByBookingIdToNull } = HostLiveEvents.actions
export default HostLiveEvents.reducer