import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getHostUpcomingEvents } from "../../../Redux/Slices/Host/UpcomingEvents/HostUpcomingEventSlice";
import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function UpcomingEventsList(params) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const upcomingEventData = useSelector(state => state?.HostUpcomingEventData?.upcomingEvents)
    const upcomingEventsLoading = useSelector(state => state?.HostUpcomingEventData?.upcomingEventsLoading)

    useEffect(() => {
        dispatch(getHostUpcomingEvents())
    }, [])


    return <>
        <Box sx={{
            padding: '15px 20px',
            backgroundColor: '#fff',
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        }}>
            <Typography sx={{ fontWeight: 700 }}>Upcoming Event list</Typography>
        </Box>
        <TableContainer sx={{ overflowX: 'unset' }} component={Paper} elevation={0} >
            <Table size="small" sx={{ mb: 1 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{ width: '5%' }} >SL.NO</TableCell>
                        <TableCell align="left" style={{ width: '30%' }}>Event Name</TableCell>
                        <TableCell align='center' sx={{ width: '25%' }} > Enrolled</TableCell>
                        <TableCell align="center" style={{ width: '25%' }}>Event Schedule</TableCell>
                        <TableCell align="center" style={{ width: '15%' }}>Participant List</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {upcomingEventsLoading ?
                        <TableCell colSpan={8} align="center"><CircularProgress /> </TableCell>
                        : Array.isArray(upcomingEventData?.data?.events) &&
                            upcomingEventData?.data?.events?.length ?

                            upcomingEventData?.data?.events?.map((row, i) => {
                                const event = row?.event && Array.isArray(row?.event) ? row?.event[0] : {};
                                
                                return <TableRow key={row.id}
                                >
                                    <TableCell align="center">{i + 1}</TableCell>

                                    <TableCell align='left' component="th" scope="row">
                                        {event?.title}
                                    </TableCell>




                                    <TableCell align="center">

                                        -
                                    </TableCell>

                                    <TableCell align="center">
                                        <Typography sx={{ fontSize: '14px' }}> {event?.duration_start_date ? moment(event?.duration_start_date).format("D MMMM YYYY") : "-"}</Typography>

                                        {event?.duration_from_time && event?.duration_to_time ?
                                            <> {moment(event?.duration_from_time, 'HH:mm:ss').format('h:mm A')} - {moment(event?.duration_to_time, 'HH:mm:ss').format('h:mm A')}</>
                                            : null
                                        }
                                    </TableCell>

                                    <TableCell align="center">
                                        <Button variant="outlined" endIcon={<ArrowForwardIosIcon style={{ fontSize: '14px' }} />}
                                            onClick={() => navigate(`/participants_management/${row?.event_id}`)}
                                        >
                                            View
                                        </Button>

                                    </TableCell>


                                </TableRow>
                            })
                            : <TableCell colSpan={8} align="center">No data found</TableCell>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}