import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    openStatus: false,
    message: '',
    severity: 'info'
}



export const openSnackbar = createAction('snack/open', function prepare(data) {
    return {
        payload: {
            ...data
        },
    }
})

const OpenSnackbar = createSlice({
    name: 'snack',
    initialState,
    reducers: {
        resetSnackBarData: (state) => {
            state.openStatus = false;
            state.message = '';
            state.severity = 'info';
        }
    },
    extraReducers: (builders) => {
        builders.addCase(openSnackbar, (state, action) => {
            state.openStatus = true;
            if (action.payload) {
                state.message = action.payload.message || state.message;
                state.severity = action.payload.severity || state.severity;
            }
        });

    }
})

export const { resetSnackBarData } = OpenSnackbar.actions
export default OpenSnackbar.reducer