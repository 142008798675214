import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as UpcomingEventIcon } from "../../../assets/UpcomingEvent/CreateEventIcon.svg"
import { useNavigate } from "react-router-dom";
import UpcomingEventList from "./UpcomingEventList";

export default function UpcomingEvents(params) {
    const navigate = useNavigate()

    return <>
        <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
            <Typography sx={{ color: "#202224", fontWeight: 700, fontSize: 18 }}>Upcoming Events</Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                <Button sx={{
                    border: '1px solid #0B6BC2',
                    color: '#0B6BC2',
                    backgroundColor: '#FFFFFF'
                }}>Draft</Button>
                <Button
                    onClick={() => navigate("/create_event")}
                    sx={{
                        backgroundColor: "#0B6BC2",
                        color: '#FFFFFF',
                        "&:hover": {
                            backgroundColor: "#0B6BC2"
                        }
                    }}
                    startIcon={<UpcomingEventIcon />}
                >
                    New event
                </Button>
            </Box>

        </Box>

        <Box sx={{ flexGrow: 1 }}>
            <UpcomingEventList />
        </Box>
    </>
}