import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LiveEventList from "./LiveEventList";

export default function LiveEvents(params) {
    const navigate = useNavigate()

    return <>
        <Box sx={{ mb: 1 }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>Live Events</Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
            <LiveEventList />
        </Box>
    </>
}