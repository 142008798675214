import { Box, Button, Grid, IconButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import useCommonStyles from "../../../Styles/CommonStyles";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as UploadIcon } from "../../../assets/CreateEvent/UploadIcon.svg"
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAllHost } from "../../../Redux/Slices/CreateEvent/CreateEventSlice";
import ValidationView from "../../../Components/common/ValidationView";

export default function ImageAndHost({
    inputData,
    setInputData,
    noOfRows,
    setNoOfRows,
    hostData,
    setHostData,
    image,
    setImage,
    coverImageUrl,
    setCoverImageUrl,
    errText,
    setErrText
}) {
    const commonStyles = useCommonStyles();
    const dispatch = useDispatch();

    const inputImg = useRef(null);
    // const [noOfRows, setNoOfRows] = useState(1)
    // const [hostData, setHostData] = useState([{ host_id: "" }])
    // const [image, setImage] = useState(null);
    // const [errText, setErrText] = useState({ image: false });



    const getAllHostData = useSelector(state => state.CreateEventData?.getAllHost);
    const getAllHostLoading = useSelector(state => state.CreateEventData?.getAllHostLoading);

    useEffect(() => {
        dispatch(getAllHost())
    }, [])




    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files && files[0]) {
            handleFile(files[0]);
        }
    };

    const handleFile = (file) => {

        let img = new Image()
        img.src = window.URL.createObjectURL(file)

        if (!file || (file && !file.type.match('image.*'))) {

            setErrText({ cover_image: "Please upload an image" });

        } else if (file.size > 20000000) {

            setErrText(prevValue => ({ ...prevValue, cover_image: 'The cover image must be less than 20MB' }))

        } else {
            img.onload = () => {

                if (img.width < 1200) {
                    setErrText(prevValue => ({ ...prevValue, cover_image: 'The cover image width must be greater than 1200px' }))
                    setTimeout(() => { setErrText(prevValue => ({ ...prevValue, cover_image: '' })) }, 5000);


                } else if (img.height < 400) {

                    setErrText(prevValue => ({ ...prevValue, cover_image: 'The cover image height must be greater than 400px' }))
                    setTimeout(() => { setErrText(prevValue => ({ ...prevValue, cover_image: '' })) }, 5000);


                } else {

                    setImage(file);
                    setCoverImageUrl(URL.createObjectURL(file))
                    setErrText({ cover_image: false });
                }
            }
        };
    }

    const handleInputChange = (e) => {
        const file = e.target.files[0];
        handleFile(file);
    };

    const handleClick = () => {
        inputImg.current.click();
    };

    const handleNoOfRows = (e) => {
        const { value } = e.target;

        const newHostData = Array.from({ length: value }, () => ({ host_id: "" }));
        setHostData([...newHostData])
        setNoOfRows(value)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData(prev => ({ ...prev, [name]: value }))
        setErrText(prev => ({ ...prev, [name]: '' }))
    }

    const handleSelectHost = (e, index) => {
        const { name, value } = e.target;
        setHostData(prev => {
            prev[index]["host_id"] = value
            return [...prev]
        })
        setErrText(prev => ({ ...prev, host_id: '' }))
    }



    return <>
        <Box mt={5}>
            <Box >
                <Typography className={commonStyles.commonFormTitle}>Upload Image*</Typography>
            </Box>


            <Typography className={commonStyles.InputLabel} >Main Cover Image</Typography>

            <Box>
                <Grid
                    container
                    xs={12}
                    gap="2.8%"
                    sx={{ padding: "30px 16px 30px 16px" }}
                >
                    <Grid xs={12} display={"flex"} justifyContent={'center'} id="cover_image" >
                        {coverImageUrl ? (
                            <Box
                                sx={{ position: 'relative', width: 'fit-content' }}
                            >

                                <img
                                    src={coverImageUrl}
                                    alt="Uploaded"
                                    style={{ maxHeight: '400px', maxWidth: '100%', objectFit: 'contain' }}
                                />
                                <IconButton
                                    style={{
                                        position: 'absolute',
                                        right: "-15px",
                                        top: "-18px",
                                        backgroundColor: 'red',
                                        color: '#fff'
                                    }}
                                    onClick={() => { setImage(null); setCoverImageUrl(null) }}
                                >
                                    <CloseIcon ></CloseIcon>
                                </IconButton>
                            </Box>
                        )
                            :
                            <Stack
                                width={"100%"}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    height: 500,
                                    padding: '24px 16px',
                                    border: errText.cover_image ? '1px solid #912018' : '1px solid #0B6BC2',
                                    borderStyle: 'dashed',
                                    borderRadius: '12px',
                                    backgroundColor: errText.cover_image ? '#FEE4E2' : '#F0F3F9',
                                    cursor: 'pointer',
                                }}
                            >
                                <Stack
                                    flex={1}
                                    direction="column"
                                    alignItems="center"
                                    textAlign="center"
                                >
                                    {Boolean(errText.cover_image) && (
                                        <Typography mb={2} sx={{ color: 'red' }}>
                                            {errText.cover_image}
                                        </Typography>
                                    )}

                                    <UploadIcon
                                        style={{ color: '#ACACAC', fontSize: 48 }}
                                        onClick={handleClick}
                                    />
                                    <input
                                        type="file"
                                        accept=".jpg,.jpeg,.png,.svg,.gif"
                                        ref={inputImg}
                                        onChange={handleInputChange}
                                        hidden
                                    />
                                    <Stack
                                        onClick={handleClick}
                                        my="5px"
                                        direction="row"
                                    >
                                        <Typography sx={{ fontSize: 16, color: '#5A5A5A' }}>
                                            Click to upload or drag & drop
                                        </Typography>
                                    </Stack>

                                    <Stack direction="column">
                                        <Typography
                                            sx={{ color: '#ACACAC', fontSize: '12px !important' }}
                                        >
                                            Cover Image must be at least 1200 pixels wide by 400 pixels high.
                                        </Typography>
                                        <Typography
                                            sx={{ color: '#ACACAC', fontSize: '12px !important' }}
                                        >
                                            Valid file formats: JPG, PNG.
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        }


                    </Grid>
                </Grid>

            </Box>

            <Box mt={2}>
                <Grid container>
                    <Grid sm={5}>


                        <Typography className={commonStyles.commonFormTitle}>Rotarian*</Typography>

                        <Box mt={3}>
                            <Typography className={commonStyles.InputLabel} >Rotarian ID Number</Typography>
                            <TextField
                                className={commonStyles.customInput}
                                fullWidth
                                placeholder="ID"
                                name="rotarian_id"
                                value={inputData?.rotarian_id}
                                onChange={(e) => handleChange(e)}
                                error={Boolean(errText?.rotarian_id)}
                                helperText={errText?.rotarian_id}
                            />
                        </Box>

                        <Box mt={2}>
                            <Typography className={commonStyles.InputLabel} >Rotarian District</Typography>
                            <TextField
                                className={commonStyles.customInput}
                                fullWidth
                                placeholder="District"
                                name="rotarian_district"
                                value={inputData?.rotarian_district}
                                onChange={(e) => handleChange(e)}
                                error={Boolean(errText?.rotarian_district)}
                                helperText={errText?.rotarian_district}
                            />
                        </Box>

                        {/* <Box mt={2}>
                            <Typography className={commonStyles.InputLabel} >Rotarian Designation</Typography>
                            <TextField
                                className={commonStyles.customInput}
                                fullWidth
                                placeholder="Designation"
                            />
                        </Box> */}


                        <Box mt={3}>
                            <Typography className={commonStyles.commonFormTitle}>Host*</Typography>
                            <Box mt={3}>
                                <Stack
                                    direction={"row"}
                                    gap={"10px"}
                                >
                                    <Typography className={commonStyles.InputLabel} >No of rows</Typography>
                                    <Select
                                        sx={{
                                            "& .MuiSelect-select.MuiOutlinedInput-input": {
                                                padding: "3.5px 14px"
                                            },
                                            width: '60px'
                                        }}
                                        onChange={(e) => handleNoOfRows(e)}
                                        value={noOfRows}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                    </Select>
                                </Stack>
                            </Box>

                            {hostData?.map((item, index) => {
                                return <Box mt={2} sx={{
                                    border: '1px solid #0B6BC2',
                                    padding: '15px',
                                    borderRadius: '8px'
                                }}>
                                    <Typography sx={{ color: "#2D2C3C", fontWeight: 700, }}>Host {index + 1}</Typography>
                                    <Select
                                        value={item.host_id}
                                        fullWidth
                                        sx={{
                                            "& .MuiSelect-select.MuiOutlinedInput-input": {
                                                padding: "6.5px 14px"
                                            },
                                            backgroundColor: "#fff"
                                        }}
                                        onChange={(e) => handleSelectHost(e, index)}
                                    >
                                        {/* <Box sx={{ width: '100%' }} value={''}>
                                            <Button fullWidth sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start'
                                            }}
                                                startIcon={<AddIcon />}
                                            >
                                                New Host
                                            </Button>
                                        </Box> */}

                                        {getAllHostData?.data?.host && Array.isArray(getAllHostData?.data?.host) &&
                                            getAllHostData?.data?.host?.map((hostData, index) => {
                                                return <MenuItem value={hostData.id} >
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {hostData.name}
                                                    </Typography>
                                                </MenuItem>
                                            })
                                        }
                                    </Select>

                                </Box>
                            })}

                            <ValidationView sx={{ fontSize: '12px' }}>{errText?.host_id}</ValidationView>
                        </Box>

                    </Grid>

                    <Grid sm={7}>

                    </Grid>
                </Grid>
            </Box>
        </Box>
    </>
}