import { Backdrop } from "@mui/material"
import DotLoader from "../../assets/Loader/DotLoader.gif"

export default function Loader({ open }) {

    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
    // onClick={handleClose}
    >
        {/* <CircularProgress color="inherit" /> */}
        <img src={DotLoader} style={{ width: 80 }} />

    </Backdrop>
}