
import useCommonStyles from "../../../Styles/CommonStyles";
// import PreviewCoverImage from "../../assets/CreateEvent/PreviewCoverImage.png"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { Circle as CircleIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { ReactComponent as TicketIcon } from "../../../assets/EventPreview/TicketIcon.svg"
import { ReactComponent as BuyTicketIcon } from "../../../assets/EventPreview/BuyTicketIcon.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/EventPreview/CalendarIcon.svg";
import { ReactComponent as ClockIcon } from "../../../assets/EventPreview/ClockIcon.svg";
import { ReactComponent as MapPinIcon } from "../../../assets/EventPreview/MapPinIcon.svg";
import PreviewMap from "./PreviewMap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

export default function ReviewAndPublish({
    coverImage = null,
    inputData,
    setInputData,
    faqData,
    TermAndCondition,
    eventDescription,
    selectedAmenities,
    inputError,
    setInputError,
    fromAmount
}) {

    const commonStyles = useCommonStyles();
    const navigate = useNavigate();



    const getAmenitiesData = useSelector(state => state.CreateEventData?.getAmenities);

    const findDuration = (startTime, endTime) => {

        if (startTime && endTime) {
            let start = moment(startTime, 'HH:mm');
            let end = moment(endTime, 'HH:mm');



            // Calculate the duration
            let duration = moment.duration(end.diff(start));

            // Get the duration in hours and minutes
            let hours = Math.floor(duration.asHours());
            let minutes = duration.minutes();

            return `${hours}hours ${Number(minutes) > 0 ? `${minutes}minutes` : ''}`
        } else return "-"
    }

    const openGoogleMaps = (lat, lng) => {
        const url = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(url, '_blank');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData(prev => ({ ...prev, [name]: value }))
        setInputError(prev => ({ ...prev, [name]: "" }))
    }

    return <>

        <Box mt={3} sx={{ border: '1px solid #0B6BC2', borderRadius: '10px', padding: '10px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Box sx={{
                    height: '370px',
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '',
                    boxShadow: "0px 10px 50px 0px #3D37F140",
                    borderRadius: '20px',
                    padding: '10px'
                }}>
                    <img src={coverImage} style={{
                        // height: '370px', 
                        height: '100%',
                        width: "100%",
                        borderRadius: '20px'
                        // objectFit: 'contain'
                    }} />
                </Box>
            </Box>

            <Box sx={{ padding: '10px', mt: 1 }}>
                <Box>
                    <Grid container
                        direction="row"
                        justifyContent="space-around"
                    // alignItems="center"
                    >
                        <Grid item sm={10}>
                            <Box>
                                <Typography
                                    sx={{ fontWeight: '600', fontSize: '18px', color: '#2D2C3C' }}
                                >
                                    {inputData?.title}
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        sx={{ fontWeight: '600', fontSize: '14px', color: '#2D2C3C' }}
                                    >
                                        {inputData?.rotarian_district ?? "-"}
                                    </Typography> &nbsp;
                                    <CircleIcon sx={{ fontSize: '10px' }} />
                                    &nbsp;
                                    <Typography sx={{ fontWeight: '600', fontSize: '14px', color: '#2D2C3C' }}>
                                        {inputData?.rotarian_id}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={2}
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <Box sx={{ width: '170px' }} >
                                <Box sx={{
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderBottom: '1px solid #0B6BC2'
                                }}>
                                    <Typography sx={{}} className={commonStyles.InputLabel}>From</Typography>
                                    <TicketIcon />
                                    <Typography sx={{ fontSize: "14px", fontWeight: '700' }}>
                                        ₹ {fromAmount}
                                    </Typography>
                                </Box>

                                <Button fullWidth variant="contained"
                                    sx={{ marginTop: '10px', borderRadius: '50px' }}
                                    startIcon={<BuyTicketIcon style={{ color: '#fff' }} />}

                                >
                                    Buy Tickets
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={1}>
                    <Typography className={commonStyles.commonFormTitle}>Date & Time</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}> <CalendarIcon style={{ padding: "5px 0px" }} /> &nbsp;
                        <Typography className={commonStyles.InputLabel}>{inputData?.duration_start_date ? moment(inputData?.duration_start_date).format('dddd, D MMMM YYYY') : null}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ClockIcon style={{ padding: "5px 0px" }} /> &nbsp;
                        <Typography className={commonStyles.InputLabel}>{Boolean(inputData?.duration_from_time) && moment(inputData?.duration_from_time, 'HH:mm').format('h:mm A')} -  {Boolean(inputData?.duration_to_time) && moment(inputData?.duration_to_time, 'HH:mm').format('h:mm A')}</Typography>&nbsp;
                        <Typography className={commonStyles.InputLabel}>{findDuration(inputData?.duration_from_time, inputData?.duration_to_time)}</Typography>
                    </Box>
                </Box>

                <Box mt={1}>
                    <Typography className={commonStyles.commonFormTitle}>Location</Typography>

                    <Grid container
                        direction="row"
                        justifyContent="flex-start"

                    >
                        <Grid item sm={5}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: { xs: "100%", sm: '70%', md: "70%", xl: '70%' } }}>
                                    <Typography variant="span" className={commonStyles.InputLabel}  >
                                        <MapPinIcon style={{ height: 20, width: 20 }} /> &nbsp;
                                        {inputData?.address ?? "-"}
                                    </Typography>
                                </Box>
                                <Button variant="contained" sx={{ borderRadius: '50px' }}
                                    onClick={() => openGoogleMaps(inputData?.latitude, inputData?.longitude)}
                                >Open in map</Button>
                            </Box>

                            <Box mt={1}>
                                <Box sx={{ height: 300, width: "100%" }}>
                                    <PreviewMap coordinates={{
                                        "lat": inputData?.latitude,
                                        "lng": inputData?.longitude
                                    }}
                                    />
                                </Box>
                            </Box>

                        </Grid>

                        <Grid item ></Grid>
                    </Grid>
                </Box>

                <Box mt={1}>
                    <Typography className={commonStyles.commonFormTitle}>Rotarian</Typography>
                    <Typography sx={{ fontSize: '14px' }}> {inputData?.rotarian_id}</Typography>
                    <Typography sx={{ fontSize: '12px' }}>{inputData?.rotarian_district}</Typography>
                </Box>

                <Box mt={1}>
                    <Typography className={commonStyles.commonFormTitle}>About the event</Typography>
                    {eventDescription ?
                        <div dangerouslySetInnerHTML={{ __html: eventDescription }} /> :
                        "-"}
                    {/* <ul>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                               
                            </Typography>
                        </li>
                    </ul> */}
                </Box>

                <Box mt={1}>
                    <Typography className={commonStyles.commonFormTitle}>Amenities</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 700, fontSize: "14px" }} >Food -</Typography> &nbsp;
                        <Stack
                            direction={"row"}
                            gap={"5px"}
                            flexWrap={"wrap"}
                        >
                            {selectedAmenities.map((value) => {
                                const selectedAmenity = getAmenitiesData?.data?.amenities?.find(item => item.id === value);
                                return <Chip key={selectedAmenity?.id} label={selectedAmenity?.name} />
                            })}


                        </Stack>

                    </Box>
                </Box>


                <Box mt={1}>
                    <Typography className={commonStyles.commonFormTitle}>Terms & Conditions</Typography>

                    {TermAndCondition ?
                        <div dangerouslySetInnerHTML={{ __html: TermAndCondition }} />
                        : "-"}
                    {/* 
                    <ul>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam
                            </Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontSize: "14px", color: '#6A6A6A' }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis arcu ut erat semper viverra vel et nulla. In dapibus a turpis vel consequat. Phasellus elementum ligula elit, non blandit est lacinia at. Praesent a orci et ex fermentum elementum maximus nec lacus. Etiam
                            </Typography>
                        </li>
                    </ul> */}

                </Box>


                <Box mt={1}>
                    <Typography className={commonStyles.commonFormTitle}>FAQ’s</Typography>

                    <div style={{ marginTop: '10px' }}>
                        {faqData && Array.isArray(faqData) && faqData?.length > 0 ?
                            faqData?.map((item, index) => {
                                return <Accordion defaultExpanded={index == 0 ? true : false} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography>{item?.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {item?.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            }) : null
                        }


                    </div>

                </Box>

            </Box>
        </Box>

        <Box mt={2}>
            <Typography className={commonStyles.commonFormTitle}>Publish Schedule <span style={{ color: 'red' }} >*</span></Typography>

            <Box sx={{ display: 'flex', gap: '15px', mt: 1 }}>
                <Box>
                    <Typography className={commonStyles.InputLabel} sx={{ fontSize: '12px' }}>Date</Typography>
                    <TextField
                        type="date"
                        className={commonStyles.datePicker}
                        name="publish_date"
                        onChange={handleChange}
                        value={inputData?.publish_date}
                        error={Boolean(inputError?.publish_date)}
                        helperText={inputError?.publish_date}
                    />
                </Box>
                <Box>
                    <Typography className={commonStyles.InputLabel} sx={{ fontSize: '12px' }}>Time</Typography>
                    <TextField
                        type="time"
                        className={commonStyles.datePicker}
                        name="publish_time"
                        onChange={handleChange}
                        value={inputData?.publish_time}
                        error={Boolean(inputError?.publish_time)}
                        helperText={inputError?.publish_time}
                    />
                </Box>
            </Box>
        </Box>
    </>
}


