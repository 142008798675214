import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const useStyles = makeStyles((theme) => ({
    root: {
        // margin: theme.spacing(2),
        // padding: theme.spacing(2),
    },
}));

const TextEditor = ({ data = '', onChange = null, ...props }) => {
    const classes = useStyles();
    const [content, setContent] = useState('');

    const handleChange = (value) => {
        setContent(value);
        if (onChange) {
            onChange(value)
        }
    };


    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
            ['bold', 'italic', 'underline', 'strike'], // Text style
            // ['link', 'image', 'blockquote', 'code-block'], // Inline and block elements
            [{ list: 'bullet' }], // Unordered list
            // [{ list: 'ordered' }], // Ordered list
            ['clean'], // Remove formatting
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        // 'link', 'image', 'blockquote', 'code-block',
        'list', 'bullet',
        // 'list', 'ordered', 
        'clean',
    ];

    return (
        <Paper elevation={0} className={classes.root}>
            <ReactQuill
                modules={modules}
                formats={formats}
                value={data ? data : content}
                onChange={handleChange}
                placeholder="Write something..."
                style={{ ...props?.style }}
            />
        </Paper>
    );
};

export default TextEditor;
