import { Box, Button, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate, useParams } from "react-router-dom";
import CustomStepper from "./Stepper";
import useCommonStyles from "../../../Styles/CommonStyles";
import { useEffect, useState } from "react";
import EssentialDetails from "./EssentialDetails";
import DescAndTC from "./DescAndTC";
import ImageAndHost from "./ImageAndHost";
import TicketAndFAQ from "./TicketAndFAQ";
import ReviewAndPublish from "./ReviewAndPublish";
import { useDispatch, useSelector } from "react-redux";
import { createEvent, createEventToNull, getEventById, getRegistrationTypeToNull, getTicketTypes, setGetAllHostToNull, setGetAmenitiesToNull, setGetClubNamesToNull, setGetEventByIdToNull, setGetFoodTypesToNull, setGetTicketTypesToNull } from "../../../Redux/Slices/CreateEvent/CreateEventSlice";
import Tickets from "./Tickets";
import { focusError, scrollIntoViewError } from "../../../Utils";
import { openSnackbar } from "../../../Redux/Slices/common/SnackbarSlice";
import axios from "axios";
import Loader from "../../../Components/common/Loader";


const initialState = {
    //--> Essential Details
    title: "",
    zone: "",
    club_name: "",
    latitude: "",
    longitude: "",
    address: "",
    duration: 0,
    is_food_available: 0,
    food_type_id: [],
    duration_start_date: "",
    duration_end_date: "",
    duration_from_time: "",
    duration_to_time: "",

    //--> Description

    //-->image &host 
    host_id: [],
    cover_image: "",

    //Tickets
    booking_start_date: "",
    booking_end_date: "",
    is_approved: 0,

    //preview
    publish_date: "",
    publish_time: ""

}



export default function CreateEvent(params) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id: editId } = useParams();

    const commonStyles = useCommonStyles()
    const [activeStep, setActiveStep] = useState(0)
    const steps = ['Essential Details', 'Description & T&C', 'Image & Host', 'Tickets', 'Review & Publish'];

    //common
    const [inputData, setInputData] = useState(initialState);
    const [inputError, setInputError] = useState({});

    // Essential Details
    const [searchLocation, setSearchLocation] = useState({})
    const [selectedAmenities, setSelectedAmenities] = useState([])


    // --> Description & T&C
    const [eventDescription, setEventDescription] = useState("")
    const [TermAndCondition, setTermAndCondition] = useState("")
    const [faqData, setFaqData] = useState([{ question: '', answer: '' }]);

    //--> image & host
    const [noOfRows, setNoOfRows] = useState(1)
    const [hostData, setHostData] = useState([{ host_id: "" }]);
    const [image, setImage] = useState(null);
    const [coverImageUrl, setCoverImageUrl] = useState(null);
    const [errText, setErrText] = useState({ image: false });

    //--> Tickets
    const [ticketTypeData, setTicketTypeData] = useState([{ ticket_name: "", price: "", registration_type_id: "" }]); //--> ticket type list
    const [annetsData, setAnnetsData] = useState([{ annet_type: "", price: "" }]); //--> annets list

    const [ticketTypeCounts, setTicketTypeCounts] = useState(1);
    const [annetCounts, setAnnetCounts] = useState(1);

    //--> preview & publish
    const [coverImage, setCoverImage] = useState(null);
    const [fromAmount, setFromAmount] = useState(0)


    const createEventData = useSelector(state => state.CreateEventData?.createEvent);
    const createEventLoading = useSelector(state => state.CreateEventData?.createEventLoading);

    const getEventByIdData = useSelector(state => state.CreateEventData?.getEventById);
    const getEventByIdLoading = useSelector(state => state.CreateEventData?.getEventByIdLoading);

    useEffect(() => {

        if (createEventData?.success === true) {
            dispatch(openSnackbar({ message: createEventData?.toast, severity: 'success' }))
            dispatch(createEventToNull())
            navigate("/upcoming_events")
        }
        else if (createEventData?.success === false &&
            createEventData?.data?.validation_error &&
            typeof createEventData?.data?.validation_error === "object"
        ) {
            const pageOne = ['title', 'zone', 'club_name', 'latitude', 'duration_start_date', 'duration_end_date', "duration_from_time", "duration_to_time", 'amenities_type_id'];
            const pageTwo = ['description', "terms_description", "faq"];
            const pageThree = ['cover_image', "rotarian_id", "rotarian_district", "host_id"];
            const pageFour = ['booking_start_date', "booking_end_date", "tickets", "annets"];

            const keys = Object.keys(createEventData?.data?.validation_error);

            for (const key of keys) {
                if (pageOne.includes(key)) {
                    setActiveStep(0)
                } else if (pageTwo.includes(key)) {
                    setActiveStep(1)
                } else if (pageThree.includes(key)) {
                    setActiveStep(2)
                } else if (pageFour.includes(key)) {
                    setActiveStep(3)
                }
            }
            setInputError(prev => ({ ...prev, ...createEventData?.data?.validation_error }))
            dispatch(createEventToNull())
        } else if (createEventData?.success === false
            && createEventData?.error
            && typeof createEventData?.error === "string") {

            dispatch(openSnackbar({ message: createEventData?.error, severity: 'error' }))
            dispatch(createEventToNull())
        }


    }, [createEventData])



    useEffect(() => {

        if (editId) {
            dispatch(getEventById({ event_id: editId }))
        }

        return () => {
            dispatch(setGetTicketTypesToNull())
            dispatch(setGetFoodTypesToNull())
            dispatch(setGetClubNamesToNull());
            dispatch(setGetAmenitiesToNull());
            dispatch(setGetAllHostToNull());
            dispatch(getRegistrationTypeToNull())
        }
    }, [])

    useEffect(() => {
        if (ticketTypeData && Array.isArray(ticketTypeData)) {
            const prices = ticketTypeData?.map((item) => {
                return item.price
            })
            const leastPrice = Math.min(...prices);
            setFromAmount(leastPrice)
        }

    }, [ticketTypeData])

    useEffect(() => {
        if (
            getEventByIdData?.success === true
            && getEventByIdData?.data?.event && editId
        ) {
            const editEventData = getEventByIdData?.data?.event;
            // console.log(getEventByIdData?.data?.event, 'getEventByIdData');      

            let newData = {}

            if (editEventData?.food_availability && Array.isArray(editEventData?.food_availability)) {
                const foodTypeId = editEventData?.food_availability?.map((item) => {
                    return item?.food_type?.id
                })
                newData.food_type_id = foodTypeId
            }

            if (editEventData?.description) {
                newData.description = editEventData?.description
                setEventDescription(editEventData?.description)
            }

            if (editEventData?.terms_condition?.description) {
                newData.terms_condition = editEventData?.terms_condition?.description
                setTermAndCondition(editEventData?.terms_condition?.description)
            }

            if (editEventData?.faq && Array.isArray(editEventData.faq)) {
                const faq = editEventData?.faq?.map((item) => ({ question: item?.question, answer: item?.answer }))
                setFaqData(faq)
            }


            if (editEventData?.cover_image) {
                setCoverImageUrl(`${process.env.REACT_APP_BASE_URL}${editEventData?.cover_image}`)
            }

            if (editEventData?.ticket) {
                newData.booking_start_date = editEventData?.ticket?.booking_start_date
                newData.booking_end_date = editEventData?.ticket?.booking_end_date
            }


            if (editEventData?.eventhost && Array.isArray(editEventData?.eventhost)) {
                const hostIdData = editEventData?.eventhost?.map((item) => ({ host_id: item?.host_id }))
                const hostIds = editEventData?.eventhost?.map((item) => item?.host_id)
                newData.host_id = hostIds
                setHostData(hostIdData)
            }

            newData.is_approved = Number(editEventData?.is_approved) ? 1 : 0;

            if (editEventData?.ticket?.registration && Array.isArray(editEventData?.ticket?.registration)) {

                const regData = editEventData?.ticket?.registration?.map((item) => ({
                    ticket_name: item?.ticket_name,
                    price: Number(item?.price),
                    registration_type_id: item?.registration_type_id
                }));
                setTicketTypeData(regData);
                setTicketTypeCounts(regData?.length)

            }

            if (editEventData?.annets && Array.isArray(editEventData?.annets)) {
                const annets = editEventData?.annets?.map((item) => ({ annet_type: item?.annet_type, price: item?.price }))
                setAnnetsData(annets);
                setAnnetCounts(annets?.length)
            }


            if (editEventData?.latitude && editEventData?.longitude) {
                setSearchLocation({ lat: editEventData?.latitude, lng: editEventData?.longitude })
            }
            if (editEventData?.amenities && Array.isArray(editEventData.amenities)) {
                const amenities = editEventData?.amenities?.map(item => item?.amenities_type?.id)
                setSelectedAmenities(amenities)
            }

            setInputData(prev => ({
                title: editEventData.title,
                zone: editEventData?.zone,
                club_name: editEventData?.club_name,
                latitude: editEventData?.latitude,
                longitude: editEventData?.longitude,
                address: editEventData?.address,
                address: editEventData?.address,
                duration: Number(editEventData?.duration) ? 1 : 0,
                duration_start_date: editEventData?.duration_start_date,
                duration_end_date: editEventData?.duration_end_date,
                duration_from_time: editEventData?.duration_from_time,
                duration_to_time: editEventData?.duration_to_time,

                is_food_available: editEventData?.food_availability && editEventData?.food_availability?.length ?
                    Number(editEventData?.food_availability[0]?.is_food_available) ? 1 : 0 :
                    0,

                rotarian_id: editEventData?.rotarian?.rotarian_id,
                rotarian_district: editEventData?.rotarian?.rotarian_district,
                ...newData
            }))

            dispatch(setGetEventByIdToNull())
        }
    }, [getEventByIdData])

    // console.log(inputData, 'inpudd');


    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            handleSubmit()
        } else {
            let isValid = validate(activeStep)
            if (isValid) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };

    const validate = (page, isPublishNow) => {
        let valid = true

        if (page === 0) {
            // const fields = ["title", "zone"]
            if (!inputData?.title) {
                setInputError(prev => ({ ...prev, title: "The title field is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, title: "" }))
            }

            if (!inputData?.zone) {
                setInputError(prev => ({ ...prev, zone: "The zone field is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, zone: "" }))
            }

            if (!inputData?.club_name) {
                setInputError(prev => ({ ...prev, club_name: "The club name field is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, club_name: "" }))
            }

            if (!inputData?.latitude && !inputData.longitude) {
                setInputError(prev => ({ ...prev, latitude: "Location is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, latitude: "" }))
            }

            if (!inputData?.duration_start_date) {
                setInputError(prev => ({ ...prev, duration_start_date: "Duration start date is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, duration_start_date: "" }))
            }

            if (!inputData?.duration_end_date) {
                setInputError(prev => ({ ...prev, duration_end_date: "Duration end date is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, duration_end_date: "" }))
            }


            if (!inputData?.duration_from_time) {
                setInputError(prev => ({ ...prev, duration_from_time: "Duration from time is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, duration_from_time: "" }))
            }

            if (!inputData?.duration_to_time) {
                setInputError(prev => ({ ...prev, duration_to_time: "Duration to time is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, duration_to_time: "" }))
            }


            if (!selectedAmenities?.length) {
                setInputError(prev => ({ ...prev, amenities_type_id: "Amenity is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, amenities_type_id: "" }))
            }

        } else if (page === 1) {

            if (!eventDescription || eventDescription === '<p><br></p>') {
                setInputError(prev => ({ ...prev, description: "Description is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, description: "" }))
            }

            if (!TermAndCondition || TermAndCondition === '<p><br></p>') {
                setInputError(prev => ({ ...prev, terms_description: "Terms & condition is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, terms_description: "" }))
            }


            if (!faqData.every(sv => sv.question && sv.answer)) {
                setInputError(prev => ({ ...prev, faq: "Please check all fields" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, faq: "" }))
            }

        } else if (page === 2) {

            if (editId) {

                if (!image && !coverImageUrl) {
                    setInputError(prev => ({ ...prev, cover_image: "Cover image is required" }))
                    valid = false
                    scrollIntoViewError("cover_image")
                } else {
                    setInputError(prev => ({ ...prev, cover_image: "" }))
                }

            } else {

                if (!image) {
                    setInputError(prev => ({ ...prev, cover_image: "Cover image is required" }))
                    valid = false
                    scrollIntoViewError("cover_image")
                } else {
                    setInputError(prev => ({ ...prev, cover_image: "" }))
                }

            }


            if (!inputData?.rotarian_id) {
                setInputError(prev => ({ ...prev, rotarian_id: "Rotarian id is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, rotarian_id: "" }))
            }

            if (!inputData?.rotarian_district) {
                setInputError(prev => ({ ...prev, rotarian_district: "Rotarian district is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, rotarian_district: "" }))
            }

            if (!hostData.every(sv => sv.host_id)) {
                setInputError(prev => ({ ...prev, host_id: "Please fill all fields" }))
                valid = 0
            } else {
                setInputError(prev => ({ ...prev, host_id: "" }))
            }



        } else if (page === 3) {

            if (!inputData?.booking_start_date) {
                setInputError(prev => ({ ...prev, booking_start_date: "Booking start date is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, booking_start_date: "" }))
            }

            if (!inputData?.booking_end_date) {
                setInputError(prev => ({ ...prev, booking_end_date: "Booking end date is required" }))
                valid = false
            } else {
                setInputError(prev => ({ ...prev, booking_end_date: "" }))
            }

            if (!ticketTypeData.every(sv => sv.ticket_name && sv.price && sv.registration_type_id)) {
                setInputError(prev => ({ ...prev, tickets: "Please fill all fields" }))
                valid = 0
            } else {
                setInputError(prev => ({ ...prev, tickets: "" }))
            }

            if (!annetsData.every(sv => sv.annet_type && sv.price)) {
                setInputError(prev => ({ ...prev, annets: "Please fill all fields" }))
                valid = 0
            } else {
                setInputError(prev => ({ ...prev, annets: "" }))
            }

        } else if (page === 4) {
            if (!isPublishNow) {
                if (!inputData?.publish_date) {
                    setInputError(prev => ({ ...prev, publish_date: "Publish date is required" }))
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, publish_date: "" }))
                }

                if (!inputData?.publish_time) {
                    setInputError(prev => ({ ...prev, publish_time: "Publish time is required" }))
                    valid = false
                } else {
                    setInputError(prev => ({ ...prev, publish_time: "" }))
                }
            }
        }
        return valid
    }

    const handleSubmit = (isPublishNow) => {

        const isValid = validate(activeStep, isPublishNow)
        if (!isValid) return;

        const formdata = new FormData();

        formdata.append("title", inputData?.title)
        formdata.append("zone", inputData?.zone)
        formdata.append("club_name", inputData?.club_name)
        formdata.append("latitude", inputData?.latitude)
        formdata.append("longitude", inputData?.longitude);
        formdata.append("address", inputData?.address);
        formdata.append("duration", inputData?.duration);
        formdata.append("duration_start_date", inputData?.duration_start_date);
        formdata.append("duration_end_date", inputData?.duration_end_date);
        formdata.append("duration_from_time", inputData?.duration_from_time);
        formdata.append("duration_to_time", inputData?.duration_to_time);
        formdata.append("is_food_available", inputData?.is_food_available);
        formdata.append("rotarian_id", inputData?.rotarian_id);
        formdata.append("rotarian_district", inputData?.rotarian_district);
        formdata.append("description", JSON.stringify(eventDescription));
        formdata.append("terms_description", JSON.stringify(TermAndCondition));
        formdata.append("cover_image", image);
        formdata.append("is_approved", inputData.is_approved);
        formdata.append("booking_start_date", inputData.booking_start_date);
        formdata.append("booking_end_date", inputData.booking_end_date);
        formdata.append("publish_date", inputData.publish_date);
        formdata.append("publish_time", inputData.publish_time);
        formdata.append("annets", JSON.stringify(annetsData));
        formdata.append("tickets", JSON.stringify(ticketTypeData));
        formdata.append("is_publish", isPublishNow ? 1 : 0)


        for (const value of selectedAmenities) {
            formdata.append("amenities_type_id[]", value);
        }
        for (const value of faqData) {
            if (value.question && value.answer) {
                formdata.append("faq[]", `${value.question},${value.answer}`);
            }
        }

        for (const iterator of hostData) {
            formdata.append("host_id[]", iterator?.host_id);
        }

        for (const value of inputData?.food_type_id) {
            formdata.append("food_type_id[]", value);
        }

        dispatch(createEvent(formdata))
    }



    return <Container>
        <Loader open={getEventByIdLoading} />
        <Box sx={{ px: 1, py: 2 }}>
            <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}
                onClick={() => navigate("/upcoming_events")}
            >
                <ArrowBackIcon />
                <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>Create new Event</Typography>
            </Box>

            <Box>
                <CustomStepper
                    activeStep={activeStep}
                    steps={steps}
                />
            </Box>


            {activeStep == 0 && <EssentialDetails
                searchLocation={searchLocation}
                setSearchLocation={setSearchLocation}
                inputData={inputData}
                inputError={inputError}
                setInputError={setInputError}
                setInputData={setInputData}
                selectedAmenities={selectedAmenities}
                setSelectedAmenities={setSelectedAmenities}
            />}
            {activeStep == 1 && <DescAndTC
                eventDescription={eventDescription}
                setEventDescription={setEventDescription}
                TermAndCondition={TermAndCondition}
                setTermAndCondition={setTermAndCondition}
                faqData={faqData}
                setFaqData={setFaqData}
                inputError={inputError}
                setInputError={setInputError}
            />}
            {activeStep == 2 && <ImageAndHost
                inputData={inputData}
                setInputData={setInputData}
                noOfRows={noOfRows}
                setNoOfRows={setNoOfRows}
                hostData={hostData}
                setHostData={setHostData}
                image={image}
                setImage={setImage}
                errText={inputError}
                setErrText={setInputError}
                coverImageUrl={coverImageUrl}
                setCoverImageUrl={setCoverImageUrl}
            />}
            {activeStep == 3 && <Tickets
                inputData={inputData}
                setInputData={setInputData}
                setInputError={setInputError}
                inputError={inputError}
                ticketTypeData={ticketTypeData}
                setTicketTypeData={setTicketTypeData}
                annetsData={annetsData}
                setAnnetsData={setAnnetsData}
                ticketTypeCounts={ticketTypeCounts}
                setTicketTypeCounts={setTicketTypeCounts}
                annetCounts={annetCounts}
                setAnnetCounts={setAnnetCounts}
            />}
            {activeStep == 4 && <ReviewAndPublish
                coverImage={coverImageUrl}
                inputData={inputData}
                setInputData={setInputData}
                faqData={faqData}
                TermAndCondition={TermAndCondition}
                eventDescription={eventDescription}
                selectedAmenities={selectedAmenities}
                setInputError={setInputError}
                inputError={inputError}
                fromAmount={fromAmount}
            />}

            <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{ visibility: activeStep > 0 ? 'visible' : 'hidden' }}
                    disabled={createEventLoading}
                >
                    Back
                </Button>

                <Box sx={{ display: 'flex', gap: '10px' }}>
                    {/* <Button
                        variant="outlined"
                        onClick={() => { }}
                        sx={{ visibility: activeStep > 0 && activeStep !== steps.length - 1 ? 'visible' : 'hidden' }}
                    >
                        Save as draft
                    </Button> */}

                    {activeStep === steps.length - 1 ? <Button
                        variant="outlined"
                        onClick={() => handleSubmit(true)}
                        disabled={createEventLoading}
                    >
                        Publish Now
                        {/* {createEventLoading ? <>&nbsp; <CircularProgress style={{ height: 20, width: 20 }} /></> : null} */}
                    </Button> : null
                    }


                    <Button variant="contained" sx={{ alignSelf: 'flex-end' }}
                        onClick={handleNext}
                        disabled={createEventLoading}
                    >
                        {activeStep === steps.length - 1 ? 'Schedule Publish' : 'Next'}
                        {/* {createEventLoading ? <>&nbsp; <CircularProgress style={{ height: 20, width: 20 }} /></> : null} */}
                    </Button>
                </Box>
            </Box>
        </Box >
    </Container>
}