import { useEffect } from "react";
import AdminRoutes from "./AdminRoutes";
import HostRoutes from "./HostRoutes";
import { getUserDetails } from "../Utils";
import { Route, Routes } from "react-router-dom";
import Header from "../Components/Header";
import NotFound from "../Components/PageNotFound";

export default function Routing(params) {
    const userDetails = getUserDetails();

    if (userDetails?.user_role_id === 1) {
        return <AdminRoutes />

    } else if (userDetails?.user_role_id === 2) {
        return <HostRoutes />

    } else return <Routes>
        <Route path="/*" element={<Header><NotFound /></Header>} />
    </Routes>
}