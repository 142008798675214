import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as UpcomingEventIcon } from "../../../assets/UpcomingEvent/CreateEventIcon.svg"
import { useNavigate } from "react-router-dom";
import UpcomingEventsList from "./UpcomingEventsList";

export default function UpcomingEvents(params) {
    const navigate = useNavigate()

    return <>
        <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
            <UpcomingEventsList />
        </Box>
    </>
}