import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../AxiosInstance";
import { getUserDetails } from "../../../../Utils";

const initialState = {
    upcomingEventsLoading: false,
    upcomingEvents: null,
    participantsListLoading: false,
    participantsList: null,
    updateBookingStatusLoading: false,
    updateBookingStatus: null,
}


export const getHostUpcomingEvents = createAsyncThunk('getUpcomingEvents', async (data, { rejectWithValue }) => {
    const userDetails = getUserDetails();
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/${userDetails?.host_id}/event_details_by_host_id`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getParticipantsList = createAsyncThunk('getParticipantsList', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/${data.event_id}/event/participants_list`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const updateBookingStatus = createAsyncThunk('updateBookingStatus', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/${data.event_id}/event/${data.booking_id}/booking_status_update`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


const HostUpComingEvent = createSlice({
    name: 'HostUpComingEvent',
    initialState,
    reducers: {
        setHostUpcomingEventsToNull: (state) => {
            state.upcomingEvents = null
        },
        setParticipantsListToNull: (state) => {
            state.participantsList = null
        },
        setUpdateBookingStatusToNull: (state) => {
            state.updateBookingStatus = null
        }
    },
    extraReducers: (builders) => {
        //upcomingEvent
        builders.addCase(getHostUpcomingEvents.fulfilled, (state, action) => {
            state.upcomingEvents = action.payload.data
            state.upcomingEventsLoading = false
        })
        builders.addCase(getHostUpcomingEvents.pending, (state, action) => {
            state.upcomingEventsLoading = true
        })
        builders.addCase(getHostUpcomingEvents.rejected, (state, action) => {
            state.upcomingEvents = action?.payload?.response?.data
            state.upcomingEventsLoading = false
        })

        //getParticipantsList
        builders.addCase(getParticipantsList.fulfilled, (state, action) => {
            state.participantsList = action.payload.data
            state.participantsListLoading = false
        })
        builders.addCase(getParticipantsList.pending, (state, action) => {
            state.participantsListLoading = true
        })
        builders.addCase(getParticipantsList.rejected, (state, action) => {
            state.participantsList = action?.payload?.response?.data
            state.participantsListLoading = false
        })

        //updateBookingStatus
        builders.addCase(updateBookingStatus.fulfilled, (state, action) => {
            state.updateBookingStatus = action.payload.data
            state.updateBookingStatusLoading = false
        })
        builders.addCase(updateBookingStatus.pending, (state, action) => {
            state.updateBookingStatusLoading = true
        })
        builders.addCase(updateBookingStatus.rejected, (state, action) => {
            state.updateBookingStatus = action?.payload?.response?.data
            state.updateBookingStatusLoading = false
        })
    }
})

export const { setHostUpcomingEventsToNull, setParticipantsListToNull, setUpdateBookingStatusToNull } = HostUpComingEvent.actions
export default HostUpComingEvent.reducer