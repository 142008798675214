import { configureStore } from "@reduxjs/toolkit";
import LoginSlice from "../Slices/Login/LoginSlice";
import SnackbarSlice from "../Slices/common/SnackbarSlice";
import CreateEventSlice from "../Slices/CreateEvent/CreateEventSlice";
import UpComingEventSlice from "../Slices/UpComingEvents/UpComingEventSlice";
import HostUpcomingEventSlice from "../Slices/Host/UpcomingEvents/HostUpcomingEventSlice";
import HostLiveEventSlice from "../Slices/Host/LiveEvents/HostLiveEventSlice";

const store = configureStore({
    reducer: {
        Login: LoginSlice,
        SnackBarData: SnackbarSlice,
        CreateEventData: CreateEventSlice,
        UpComingEventData:UpComingEventSlice,        
        HostUpcomingEventData:HostUpcomingEventSlice,
        HostLiveEventData:HostLiveEventSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export default store;