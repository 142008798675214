import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../AxiosInstance";

const initialState = {
    upcomingEventsLoading: false,
    upcomingEvents: null,

}

export const getUpcomingEvents = createAsyncThunk('getUpcomingEvents', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get_events`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})



const UpComingEvent = createSlice({
    name: 'UpComingEvent',
    initialState,
    reducers: {
        setUpcomingEventsToNull: (state) => {
            state.upcomingEvents = null
        },


    },
    extraReducers: (builders) => {
        //upcomingEvent
        builders.addCase(getUpcomingEvents.fulfilled, (state, action) => {
            state.upcomingEvents = action.payload.data
            state.upcomingEventsLoading = false
        })
        builders.addCase(getUpcomingEvents.pending, (state, action) => {
            state.upcomingEventsLoading = true
        })
        builders.addCase(getUpcomingEvents.rejected, (state, action) => {
            state.upcomingEvents = action?.payload?.response?.data
            state.upcomingEventsLoading = false
        })
    }
})

export const { setUpcomingEventsToNull } = UpComingEvent.actions
export default UpComingEvent.reducer