import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../AxiosInstance";

const initialState = {
    loading: false,
    getAmenitiesLoading: false,
    getAllHostLoading: false,
    getRegistrationTypeLoading: false,
    createEventLoading: false,
    getEventByIdLoading: false,

    getTicketTypes: null,
    getFoodTypes: null,
    getClubNames: null,
    getAmenities: null,
    getAllHost: null,
    getRegistrationType: null,
    createEvent: null,
    getEventById: null,
}

export const getTicketTypes = createAsyncThunk('getTicketTypes', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get_ticket_types`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getFoodTypes = createAsyncThunk('getFoodTypes', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/food_type`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getClubNames = createAsyncThunk('getClubNames', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get_club_name`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAmenities = createAsyncThunk('getAmenities', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/amenities`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAllHost = createAsyncThunk('getAllHost', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get_host`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const getRegistrationType = createAsyncThunk('getRegistrationType', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/get_registration_type`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const createEvent = createAsyncThunk('createEvent', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/create_event`, data);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const getEventById = createAsyncThunk('getEventById', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/event/${data.event_id}`);
        return response;
    } catch (err) {
        return rejectWithValue(err)
    }
})



const CreateEvent = createSlice({
    name: 'CreateEvent',
    initialState,
    reducers: {
        setGetTicketTypesToNull: (state) => {
            state.getTicketTypes = null
        },
        setGetFoodTypesToNull: (state) => {
            state.getFoodTypes = null
        },
        setGetClubNamesToNull: (state) => {
            state.getClubNames = null
        },
        setGetAmenitiesToNull: (state) => {
            state.getAmenities = null
        },
        setGetAllHostToNull: (state) => {
            state.getAllHost = null
        },
        getRegistrationTypeToNull: (state) => {
            state.getRegistrationType = null
        },
        createEventToNull: (state) => {
            state.createEvent = null
        },
        setGetEventByIdToNull: (state) => {
            state.getEventById = null
        }

    },
    extraReducers: (builders) => {
        //--> getTicketTypes
        builders.addCase(getTicketTypes.fulfilled, (state, action) => {
            state.getTicketTypes = action.payload.data
            state.loading = false
        })
        builders.addCase(getTicketTypes.pending, (state, action) => {
            state.loading = true
        })
        builders.addCase(getTicketTypes.rejected, (state, action) => {
            state.getTicketTypes = action?.payload?.response?.data
            state.loading = false
        })

        //--> getFoodTypes
        builders.addCase(getFoodTypes.fulfilled, (state, action) => {
            state.getFoodTypes = action.payload.data
            state.loading = false
        })
        builders.addCase(getFoodTypes.pending, (state, action) => {
            state.loading = true
        })
        builders.addCase(getFoodTypes.rejected, (state, action) => {
            state.getFoodTypes = action?.payload?.response?.data
            state.loading = false
        })

        //--> getClubNames
        builders.addCase(getClubNames.fulfilled, (state, action) => {
            state.getClubNames = action.payload.data
            state.loading = false
        })
        builders.addCase(getClubNames.pending, (state, action) => {
            state.loading = true
        })
        builders.addCase(getClubNames.rejected, (state, action) => {
            state.getClubNames = action?.payload?.response?.data
            state.loading = false
        })

        //--> getAmenities
        builders.addCase(getAmenities.fulfilled, (state, action) => {
            state.getAmenities = action.payload.data
            state.getAmenitiesLoading = false
        })
        builders.addCase(getAmenities.pending, (state, action) => {
            state.getAmenitiesLoading = true
        })
        builders.addCase(getAmenities.rejected, (state, action) => {
            state.getAmenities = action?.payload?.response?.data
            state.getAmenitiesLoading = false
        })

        //--> getAllHost
        builders.addCase(getAllHost.fulfilled, (state, action) => {
            state.getAllHost = action.payload.data
            state.getAllHostLoading = false
        })
        builders.addCase(getAllHost.pending, (state, action) => {
            state.getAllHostLoading = true
        })
        builders.addCase(getAllHost.rejected, (state, action) => {
            state.getAllHost = action?.payload?.response?.data
            state.getAllHostLoading = false
        })

        //--> getRegistrationType
        builders.addCase(getRegistrationType.fulfilled, (state, action) => {
            state.getRegistrationType = action.payload.data
            state.getRegistrationTypeLoading = false
        })
        builders.addCase(getRegistrationType.pending, (state, action) => {
            state.getRegistrationTypeLoading = true
        })
        builders.addCase(getRegistrationType.rejected, (state, action) => {
            state.getRegistrationType = action?.payload?.response?.data
            state.getRegistrationTypeLoading = false
        })

        //--> CreateEvent
        builders.addCase(createEvent.fulfilled, (state, action) => {
            state.createEvent = action.payload.data
            state.createEventLoading = false
        })
        builders.addCase(createEvent.pending, (state, action) => {
            state.createEventLoading = true
        })
        builders.addCase(createEvent.rejected, (state, action) => {
            state.createEvent = action?.payload?.response?.data
            state.createEventLoading = false
        })

        //--> getEventByid
        builders.addCase(getEventById.fulfilled, (state, action) => {
            state.getEventById = action.payload.data
            state.getEventByIdLoading = false
        })
        builders.addCase(getEventById.pending, (state, action) => {
            state.getEventByIdLoading = true
        })
        builders.addCase(getEventById.rejected, (state, action) => {
            state.getEventById = action?.payload?.response?.data
            state.getEventByIdLoading = false
        })


    }
})

export const {
    setGetTicketTypesToNull,
    setGetFoodTypesToNull,
    setGetClubNamesToNull,
    setGetAmenitiesToNull,
    setGetAllHostToNull,
    getRegistrationTypeToNull,
    createEventToNull,
    setGetEventByIdToNull
} = CreateEvent.actions;

export default CreateEvent.reducer