import axios from "axios";
  
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
 
AxiosInstance.interceptors.request.use(
  (request) => {
    const token =
      localStorage.getItem("token") != null
        ? localStorage.getItem("token")
        : null;
    request.headers.Authorization = `Bearer ${token}`;
     
    return request;
  },
 
  (err) => {
    Promise.reject(err);
  }
);
 
export default AxiosInstance;