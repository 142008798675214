// src/MapComponent.js
import React, { useEffect, useRef } from 'react';


const MapComponent = ({ coordinates }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const { L } = window;

    let Marker = null

    useEffect(() => {
        mapRef.current = L.map('map').setView([51.505, -0.09], 13);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; OpenStreetMap contributors',
        }).addTo(mapRef.current);

        return () => {
            mapRef.current.remove();
        };
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            if (coordinates && coordinates.lat && coordinates.lng) {

                // If a marker already exists, remove it
                if (markerRef.current) {
                    mapRef.current.removeLayer(markerRef.current);
                }

                // Add new marker
                markerRef.current = L.marker([coordinates.lat, coordinates.lng]).addTo(mapRef.current);
                mapRef.current.setView([coordinates.lat, coordinates.lng], 13);

            } else if (markerRef.current) {
                console.log('else');

                // If coordinates are empty and marker exists, remove the marker
                mapRef.current.removeLayer(markerRef.current);
                markerRef.current = null;

            }
        }
    }, [coordinates]);

    return <div id="map" style={{ height: '100%', width: '100%' }}></div>;
};

export default MapComponent;
