import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import useCommonStyles from "../../../Styles/CommonStyles";
import TextEditor from "../../../Components/common/TextEditor";
import { useState } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import { ReactComponent as RemoveButtonIcon } from "../../../assets/CreateEvent/RemoveButtonIcon.svg"
import ValidationView from "../../../Components/common/ValidationView";


export default function DescAndTC({
    eventDescription,
    setEventDescription,
    TermAndCondition,
    setTermAndCondition,
    faqData,
    setFaqData,
    inputError,
    setInputError
}) {
    const commonStyles = useCommonStyles();

    // const [eventDescription, setEventDescription] = useState("")
    // const [TermAndCondition, setTermAndCondition] = useState("")
    // const [faqData, setFaqData] = useState([{ question: '', answer: '' }]);

    const handleAddFaq = () => {
        setFaqData(prev => ([...prev, { question: '', answer: '' }]))
    }
    const handleRemoveFaq = (index) => {
        setFaqData(prev => {
            prev.splice(index, 1)
            return [...prev]
        })
    }

    const handleFaqChange = (e, index) => {
        const { name, value } = e.target;

        setFaqData(prev => {
            prev[index][name] = value
            return [...prev]
        })
        setInputError(prev => ({ ...prev, faq: "" }))
    }

    return <>
        <Box mt={5}>
            <Box >
                <Typography className={commonStyles.commonFormTitle}>Event Description*</Typography>
            </Box>

            <Box mt={2}>
                <TextEditor
                    data={eventDescription}
                    onChange={(e) => {
                        setEventDescription(e);
                        setInputError(prev => ({ ...prev, description: "" }))
                    }}
                />
                <ValidationView sx={{ fontSize: "12px" }}>{inputError?.description}</ValidationView>
            </Box>


            <Box mt={3} >
                <Typography className={commonStyles.commonFormTitle}>Terms & Conditions*</Typography>
            </Box>

            <Box mt={2}>
                <TextEditor
                    data={TermAndCondition}
                    onChange={(e) => {
                        setTermAndCondition(e);
                        setInputError(prev => ({ ...prev, terms_description: "" }))
                    }}
                />
                <ValidationView sx={{ fontSize: "12px" }}>{inputError?.terms_description}</ValidationView>
            </Box>


            <Box mt={3}>
                <Grid container>
                    <Grid item sm={6}>
                        <Typography className={commonStyles.commonFormTitle}>FAQ <span style={{ color: 'red' }}>*</span></Typography>

                        {/* <Box>
                            <Typography className={commonStyles.InputLabel} >Question 1</Typography>
                            <TextField
                                className={commonStyles.FaqInput}
                                fullWidth
                                placeholder="Question"
                                multiline
                            />

                            <TextField
                                sx={{ mt: 1 }}
                                className={commonStyles.FaqInput}
                                fullWidth
                                placeholder="Answer"
                                multiline
                            />
                        </Box> */}

                        <Box >
                            {faqData?.map((item, index) => {
                                return <Box mt={2}>
                                    <Typography className={commonStyles.InputLabel} sx={{ display: 'flex', alignItems: 'center' }} > <IconButton sx={{ visibility: index > 0 ? "visible" : 'hidden' }} onClick={() => handleRemoveFaq(index)} ><RemoveButtonIcon /></IconButton> &nbsp; Question {index + 1}</Typography>
                                    <TextField
                                        className={commonStyles.FaqInput}
                                        fullWidth
                                        placeholder="Question"
                                        multiline
                                        name="question"
                                        value={item.question}
                                        onChange={(e) => handleFaqChange(e, index)}
                                    />

                                    <TextField
                                        sx={{ mt: 1 }}
                                        className={commonStyles.FaqInput}
                                        fullWidth
                                        placeholder="Answer"
                                        multiline
                                        name="answer"
                                        value={item.answer}
                                        onChange={(e) => handleFaqChange(e, index)}
                                    />
                                </Box>
                            })}
                            <ValidationView sx={{ fontSize: '12px' }}>{inputError?.faq}</ValidationView>
                        </Box>
                        <Button
                            sx={{ marginTop: '10px', borderRadius: '50px' }}
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => handleAddFaq()}

                        >Add Question</Button>

                    </Grid>
                    <Grid item sm={6}></Grid>
                </Grid>

            </Box>

        </Box>
    </>
}